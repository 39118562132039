// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import './bunker.css';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

import DataTable from 'react-data-table-component';

// Data
import { useEffect, useState } from 'react';
import { getAPICall } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import axios from 'axios';
import { RoomTwoTone } from '@mui/icons-material';
import { Button, CircularProgress, Icon, Input, SvgIcon } from '@mui/material';
import MDInputRoot from 'components/MDInput/MDInputRoot';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import AddCompanyForm from 'layouts/addCompany';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { postAPICall } from 'shared';
import { openNotificationWithIcon } from 'shared';
import AddRolesForm from 'layouts/addRoleForm';
import moment from 'moment/moment';
import { Typography } from 'antd';

function BunkerPrice() {
  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: undefined,
  });
  const [isAddCompany, setIsAddComapny] = useState(false);
  const [filtered_items, setFiltereditems] = useState({});
  const [filterText, setfilterText] = useState('');
  const [pending, setPending] = useState(true);
  const[totalData,setTotalData]=useState(null)

  const filterdata =
    state?.rows?.filter((item) =>
      JSON.stringify(item)
        .toLocaleLowerCase()
        .includes(filterText.toLowerCase()),
    ) || [];

  const columns = [
    {
      name: 'Port',
      selector: (row) => row?.port,
      sortable: true,
      isView: false,
      center: 'true',
      // width: '140px',
    },
    {
      name: 'Country',
      selector: (row) => row?.country,
      sortable: true,
      // width: '150px',
      center: 'true',
    },
    {
      name: 'Grade',
      selector: (row) => row?.grade,
      sortable: true,
      // width: '120px',
      center: 'true',
    },
    {
      name: 'Price($)',
      selector: (row) => row?.price,
      sortable: true,
      cell: (row) => (
        <MDTypography component="a" variant="caption" color="black">
          {row?.price}
        </MDTypography>
      ),
      center: 'true',
      // width: '120px',
    },
    {
      name: 'Updated',
      selector: (row) => row?.lastUpdated,
      cell: (row) => (
        <MDTypography component="a" variant="caption" color="black">
          {moment(row.lastUpdated).format('DD-MM-YYYY HH:mm')}
        </MDTypography>
      ),
      center: 'true',
      // width: '160px',
    },

    {
      name: 'Change($)',
      selector: (row) => row?.netChange,
      // width: '120px',
      center: 'true',
      cell: (row) => {
        if (row.netChange == null) {
          return (
            <MDTypography component="a" variant="caption" color="black">
              N/A
            </MDTypography>
          );
        }
        return row?.netChange;
      },
    },
  ];
  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = async () => {
    try {
      const requestOptions = {
        method: 'GET',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}bunker/spotprice?api_mode=fast`,
        requestOptions,
      );
      const respdata = await resp.json();
      console.log('Bunker REsponse', respdata);
      if (respdata) {
        handleTableData(respdata);
        setPending(false);
      } else {
        openNotificationWithIcon('error', 'Something went worng.');
      }

      const resp2 = await fetch(
        `${URL_WITH_VERSION}bunker/spotsprice`,
        requestOptions,
      );
      const respdata2 = await resp2.json();
      if(respdata2){
      handleTableData(respdata2);
      }

    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  };

  const handleTableData = (responseData) => {
    let dataArr = [];
    let futureDataArr = [];

    responseData?.BunkerExSpotPrices?.map((el) => {
      dataArr.push({
        port: el.Port,
        country: el.Country,
        grade: el.Grade,
        price: el.Price,
        lastUpdated: moment(el.LastUpdatedOnUTC).format('YYYY-MM-DD HH:mm'),
        netChange: el.NetChange,
      });
    });
    responseData?.BunkerExFuturePrices?.map((el) => {
      futureDataArr.push({
        port: el.Port,
        grade: el.Grade,
        lastUpdated: moment(el.LastUpdatedOnUTC).format('YYYY-MM-DD HH:mm'),
        price: el.Price,
      });
    });
    setState((prevState) => ({ ...prevState, rows: dataArr }));
    setTotalData(dataArr.length);
  };

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      editData: undefined,
      isView: false,
    }));
    setIsAddComapny(false);
  };

  const { editData } = state;

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    minWidth: '200px',
                    maxWidth: '600px',
                  }}
                  onChange={(e) => setfilterText(e.target.value)}
                />
                
              </div>
              <br />
              <br />
              <Card>
                <MDBox>
                  <DataTable
                    columns={columns}
                    data={filterdata}
                    pagination
                    progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      {isAddCompany === true ? (
        <AddRolesForm
          visible={true}
          onCancel={onCancel}
          onSave={(value) => {
            console.log(value);
          }}
          editData={editData}
          isView={state?.isView}
        />
      ) : undefined}
    </>
  );
}

export default BunkerPrice;
