import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Switch,
  Select,
  theme,
  DatePicker,
} from "antd";
import MultipleSelect, { StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";
import { SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { postAPICall } from "shared";
import { URL_WITH_VERSION } from "shared";
import moment from "moment";
import { openNotificationWithIcon } from "shared";

const { Item } = Form;
const Option = Select.Option;

function AdduserModal({ visible, onCancel, editMode, editData, isView }) {
  const formItemLayout = {
    labelCol: { span: 24 }, // Change the span value as needed
    wrapperCol: { span: 24 }, // Change the span value as needed
  };

  const [count, setCount] = useState(0);
  const [roleList, setRoleList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  //   const loadOptions = async () => {
  //     try {
  //       const requestOptions = {
  //         method: 'GET',
  //         redirect: 'follow',
  //       };

  //       const resp = await fetch(
  //         `${URL_WITH_VERSION}accounts/modules-details`,
  //         requestOptions,
  //       );
  //       const respdata = await resp.json();
  //       if (respdata) {
  //         const data = respdata.map((e, i) => {
  //           return { value: e.module_name, label: e.module_name };
  //         });
  //         return data;
  //       } else {
  //         openNotificationWithIcon('error', 'Something went worng.');
  //       }
  //     } catch (err) {
  //       openNotificationWithIcon('error', err);
  //     }
  //   };
  //   const companyOptions = async () => {
  //     try {
  //       const requestOptions = {
  //         method: 'GET',
  //         redirect: 'follow',
  //       };

  //       const resp = await fetch(
  //         `${URL_WITH_VERSION}accounts/company-details`,
  //         requestOptions,
  //       );
  //       const respdata = await resp.json();
  //       console.log('company Detail-->',respdata)
  //       if (respdata) {
  //         const data = respdata.map((e, i) => {
  //           return { value: e.id, label: e.company_name };
  //         });
  //         return data;
  //       } else {
  //         openNotificationWithIcon('error', 'Something went worng.');
  //       }
  //     } catch (err) {
  //       openNotificationWithIcon('error', err);
  //     }
  //   };

  //   const userData = async () => {
  //     try {
  //         const userResp = await fetch(
  //           `${URL_WITH_VERSION}accounts/user-details`,
  //           {method:"POST",
  //           headers:{
  //             "Content-Type": "application/json"
  //           } ,
  //           body: JSON.stringify({
  //             company_id: editData?.company_id.id
  //           })}
  //         );
  //         let roleList = await roleListData.json();
  //         console.log("roleList-->>>", roleList);
  //         if (setRoleList) {
  //           setRoleList(roleList);
  //         }
  //       } catch (err) {
  //         console.log("error while fetching", err);
  //       }
  //   }

  const { token } = theme.useToken();
  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    display: "flex",
    flexDirection: "column",
  };

  useEffect(() => {
    // setFormdata();
    // getCompanyData();
    getRoleList();
    getCompanyList()
  }, [count]);

  const [form] = Form.useForm();
  //   const setFormdata = ()=>{
  //     if (editData?.Expire_date) {
  //       setExpiryDate(moment(editData.Expire_date));
  //     }
  //     editData.Expire_date = moment(editData?.Expire_date)
  //     editData.companyName = editData?.company?.first_name
  //     editData.product_name = editData?.product_name
  //     editData.company_email = editData?.company?.email
  //     editData.subs_type = editData?.subScription_type
  //     editData.number_of_users = editData?.number_of_users
  //     form.setFieldsValue(editData);
  //   }

  //   const getCompanyData = async () => {
  //     if (editData?.id) {
  //       const requestOptions = {
  //         method: 'GET',
  //         redirect: 'follow',
  //       };
  //       const resp = await fetch(
  //         `${URL_WITH_VERSION}accounts/subscriber/${editData.id}`,
  //         requestOptions,
  //       );
  //       const data = await resp.json();
  //       if (data) {
  //         data['expiry_date'] = data['expiry_date']
  //           ? moment(data['expiry_date'])
  //           : moment();
  //         data['status'] = data['status'] ? true : false;
  //         data['plan_id'] = [
  //           {
  //             value: data['plan_id']['id'],
  //             label: data['plan_id']['plan_name'],
  //           },
  //         ];
  //         form.setFieldsValue(data);
  //       } else {
  //         openNotificationWithIcon('error', 'Something went worng.');
  //       }
  //     }
  //   };

  const getRoleList = async () => {
    try {
      const roleListData = await fetch(
        `${URL_WITH_VERSION}accounts/role-details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let roleList = await roleListData.json();
      console.log("roleList-->>>", roleList);
      if (setRoleList) {
        setRoleList(roleList);
      }
    } catch (err) {
      console.log("error while fetching", err);
    }
  };
  const getCompanyList = async () => {
    const companyResp = await fetch(
        `${URL_WITH_VERSION}accounts/company-details`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const companylist = await companyResp.json();
      setCompanyList(companylist)
  }

  const handleSave = async () => {
    let _method = "POST";
    if (editData?.id) {
      _method = "PUT";
    }
    
    try {
      const values = await form.validateFields();
      values["status"] = values["status"] ? 1 : 0;
      values["id"] = editData?.id;
      
      if (values["company_id"]) {
        values["company_id"] = values["company_id"]["value"];
      }
      
      const response = await fetch(`${URL_WITH_VERSION}accounts/add-user`, {
        method: _method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      });
      
      const data = await response.json();
      
      if (data.status === "1") {
        openNotificationWithIcon(
          "success",
          "User Details Are saved Successfully"
        );
        setCount(count + 1);
        onCancel();
      } else {
        openNotificationWithIcon("info", data.msg || "An error occurred");
      }
    } catch (errorInfo) {
      openNotificationWithIcon("error", "Please Fill All Mandatory Fields");
    }
  };
  
  

  return (
    <Modal
      title={
        <div style={{ textAlign: "center" }}>
          {editData?.id ? "Edit User" : "Add User"}
        </div>
      }
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={[
        <Button
          key="save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <fieldset style={{ border: "none" }}>
        <input type="hidden" value={count} />
        <Form form={form} {...formItemLayout} style={formStyle}>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="User Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter the User Name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Company Name"
                name="company_name"
                rules={[
                  { required: true, message: "Please enter the Company Name" },
                ]}
              >
                <Select 
                    showSearch
                    placeholder="Select Company"
                    options={
                    companyList.map((cname) => ({
                        value: cname?.id,
                        label: cname?.company_name
                    }))
                    }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please enter the Email" }]}
              >
                <Input type="email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please enter the Password" },
                ]}
              >
                <Input type="password" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Phone Number"
                name="phone_number"
                rules={[
                  { required: true, message: "Please enter the Phone Number" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Role"
                name="role"
                rules={[{ required: true, message: "Please enter the Role" }]}
              >
                <Select
                  showSearch
                  placeholder="Select Role"
                  options={roleList.map((role) => ({
                    value: role.label,
                    label: role.label,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </fieldset>
    </Modal>
  );
}

AdduserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  editMode: PropTypes.func.isRequired,
  isView: PropTypes.bool.isRequired,
};

export default AdduserModal;
