// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { getAllRolesService } from 'services/all';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

import DataTable from 'react-data-table-component';

// Data
import { useEffect, useState } from 'react';
import { getAPICall } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import axios from 'axios';
import { RoomTwoTone } from '@mui/icons-material';
import { Button, CircularProgress, Icon, Input, SvgIcon } from '@mui/material';
import MDInputRoot from 'components/MDInput/MDInputRoot';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import AddCompanyForm from 'layouts/addCompany';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { postAPICall } from 'shared';
import { openNotificationWithIcon } from 'shared';
import AddRolesForm from 'layouts/addRoleForm';
import EditRole from 'layouts/editRoleForm/editrole';
import Badge from "@material-ui/core/Badge";

function Roles() {
  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: undefined,
  });
  const [isAddCompany, setIsAddComapny] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [filterText, setfilterText] = useState('');
  const [pending, setPending] = useState(true);
  const [count, setCount] = useState(0);
  const [filterdata,setFilterData] = useState([])


  // filterdata =
  //   state?.rows?.filter((item) =>
  //     JSON.stringify(item)
  //       .toLocaleLowerCase()
  //       .includes(filterText.toLowerCase()),
  //   ) || [];

  const columns = [
    {
      name: 'Role Name',
      selector: (row) => row?.name,
      sortable: true,
      isView: false,
      center: true
    },
    {
      name: 'Label',
      selector: (row) => row?.label,
      sortable: true,
      center: true
    },
    {
      name: 'Write',
      selector: (row) => row?.write,
      sortable: true,
      center: true,
      cell: (row) => {
        let status = row?.write;
        if (status == 1) {
          return (
            
            <span className="status_tags Activetags">Allowed</span>
          );
        }
        return (
          
          <span className="status_tags Processingtags">Not Allowed</span>
        );
      },
    },
    {
      name: 'Value',
      center: true,
      selector: (row) => row?.value,
    },
    {
      name: 'Actions',
      center: true,
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <EditOutlined onClick={() => handleEdit(row)} />
        </MDTypography>
      ),
      right: true,
      width: '80px',
    },
  ];

  useEffect(() => {
    getTableData();
  }, [count]);

  const getTableData = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/role-details`,
        requestOptions,
      );
      const respdata = await resp.json();
      setFilterData(respdata)
      if (respdata) {
        setState((prevState) => ({ ...prevState, rows: respdata }));
        setPending(false);
      } else {
        openNotificationWithIcon('error', 'Something went worng.');
      }
    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  };

  const handleEdit = (row) => {
    setState((prevState) => ({ ...prevState, editData: row, isView: true }));
    // setIsAddComapny(true);
    setEditUserModal(true);
  };

  const handleView = (row) => {
    setState((prevState) => ({ ...prevState, editData: row }));
    setIsAddComapny(true);
  };

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      editData: undefined,
      isView: false,
    }));
    setIsAddComapny(false);
    setEditUserModal(false);
    setCount(count + 1)
  };

  const { editData } = state;

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    minWidth: '200px',
                    maxWidth: '600px',
                  }}
                  onChange={(e) => {
                    setfilterText(e.target.value)
                    setFilterData(state?.rows?.filter((item) =>
                      JSON.stringify(item)
                      .toLocaleLowerCase()
                      .includes(filterText.toLowerCase())
                    ))
                  }}
                />
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddComapny(true)}
                >
                  Add Roles
                </MDButton>
              </div>
              <br />
              <br />
              <Card>
                <MDBox key={count}>
                  <DataTable
                    columns={columns}
                    data={filterdata}
                    pagination
                    progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      {isAddCompany === true ? (
        <AddRolesForm
          visible={true}
          onCancel={onCancel}
          editData={editData}
          isView={state?.isView}
          getTableData={getTableData}
        />
      ) : undefined}

      {editUserModal === true ? (
        <EditRole
        visible={true}
        onCancel={onCancel}
        onSave={(value) => {
          console.log(value);
        }}
        editData={editData}
        isView={state?.isView}
        getTableData={getTableData}
      />
      ) : undefined
      }
    </>
  );
}

export default Roles;
