import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Select,
  theme,
  notification,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { postAPICall } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import { openNotificationWithIcon } from 'shared';

const { Item } = Form;
const { Option } = Select;

function EditQuery({ visible, onCancel, editMode, editData }) {
  const [formData, setFormData] = useState(null);

  const { token } = theme.useToken();

  const formItemLayout = {
    labelCol: { span: 24 }, // Change the span value as needed
    wrapperCol: { span: 24 }, // Change the span value as needed
  };
  
  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  };

  useEffect(() => {
    // const getCompanyData = async () => {
    //   if (editData?.id) {
    //     const requestOptions = {
    //       method: 'GET',
    //       redirect: 'follow',
    //     };

    //     const resp = await fetch(
    //       `${URL_WITH_VERSION}accounts/module/${editData.id}`,
    //       requestOptions,
    //     );
    //     const data = await resp.json();
    //     if (data) {
    //       setFormData(data);
    //     } else {
    //       openNotificationWithIcon('error', 'Something went wrong.');
    //     }
    //   }
    // };
    // getCompanyData();
    if(editData?.id){
      setFormdata();
    }
  }, []);

  const [form] = Form.useForm();
  const setFormdata = () => {
    editData.status = editData?.status;
    editData.company_name = editData?.company_name;
    editData.email = editData?.email;
    editData.id = editData?.id;
    editData.message = editData?.message;
    editData.priority_level = editData?.priority_level;
    editData.subject = editData?.subject;
    editData.created = moment(editData?.created).format('DD-MM-YYYY HH:mm');
    editData.updated = moment().format('DD-MM-YYYY HH:mm');

    form.setFieldsValue(editData);
  };

  const handleSave = () => {
    let _method = 'post';
    let url = `${URL_WITH_VERSION}voyage-estimate/customer-support-sa`;
    if (editData?.id) {
      _method = 'put';
      url = `${URL_WITH_VERSION}voyage-estimate/customer-support-sa`;
    }
    form
      .validateFields()
      .then((values) => {
        if (editData?.id) values['id'] = editData?.id;
        values['updated'] = moment().format('DD-MM-YYYY HH:mm')

        postAPICall(url, values, _method, (data) => {
          if (data.status === true) {
            openNotificationWithIcon(
              'success',
              'Query Details Are Updated Successfully',
            );
            onCancel()
          } else {
            openNotificationWithIcon('error', data.msg);
          }
        });
      })
      .catch((errorInfo) => {
        openNotificationWithIcon('error', "Please Fill All Mandatory Fields");
      });
  };

  // Set initial form values when in "edit" mode
  if (formData) {
    form.setFieldsValue(formData);
  }

  return (
    <Modal
      title={
        <div style={{ textAlign: 'center' }}>
          {editData?.id ? 'Edit Query' : 'Edit Query'}
        </div>
      }
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={[
        <Button
          key="save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} {...formItemLayout} style={formStyle}>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Company Name"
              name="company_name"
            //   rules={[{ required: true, message: 'Please enter the Company Name' }]}
            >
              <Input disabled style={{ width: '100%', color: 'black' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Email"
              name="email"
            //   rules={[{ required: true, message: 'Please enter the Email' }]}
            >
              <Input disabled type='email' style={{ width: '100%', color: 'black' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Date"
              name="created"
            //   rules={[{ required: true, message: 'Please enter the Email' }]}
            >
              <Input disabled type='text' style={{ width: '100%', color: 'black' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Priority Level"
              name="priority_level"
              rules={[{ required: true, message: 'Please enter the Priority Level' }]}
            >
              <Select style={{ width: '100%' }}>
                <Option value="normal">Normal</Option>
                <Option value="important">Important</Option>
                <Option value="urgent">Urgent</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: 'Please enter the Status' }]}
            >
              <Select style={{ width: '100%' }}>
                <Option value="1">Pending</Option>
                <Option value="2">In Progress</Option>
                <Option value="3">Resolved</Option>
                <Option value="4">Rejected</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Subject"
              name="subject"
              rules={[{ required: true, message: 'Please enter the Subject' }]}
            >
              <Input type='text' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true, message: 'Please enter the message' }]}
            >
              <TextArea rows={4} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

EditQuery.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
};

export default EditQuery;
