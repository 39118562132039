import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Switch,
  Select,
  theme,
  DatePicker,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { postAPICall } from "shared";
import { URL_WITH_VERSION } from "shared";
import moment from "moment";
import { openNotificationWithIcon } from "shared";

const { Item } = Form;
const Option = Select.Option;

function MenuListModal({ visible, onCancel, editData }) {
  const [formData, setFormData] = useState([]);
  const [parent, setParent] = useState([]);

  const formItemLayout = {
    labelCol: { span: 24 }, // Change the span value as needed
    wrapperCol: { span: 24 }, // Change the span value as needed
  };

  const { token } = theme.useToken();
  const formStyle = {
    maxWidth: "none",
    background: token?.colorFillAlter,
    borderRadius: token?.borderRadiusLG,
    padding: 24,
    display: "flex",
    flexDirection: "column",
  };

  formStyle.labelStyle = {
    flex: "1",
    maxWidth: "40%", // Adjust this value to control the maximum label width
    paddingRight: "10px", // Add spacing between label and input
    textAlign: "right", // Right-align the label text
  };

  // Styles for input boxes
  formStyle.inputStyle = {
    flex: "1", // Allow input boxes to take up the remaining space
  };

  useEffect(() => {
    getParentName();
    if (editData?.id) {
      setFormdata();
    }
  }, []);

  const getParentName = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const resp = await fetch(
      `${URL_WITH_VERSION}accounts/admin_list`,
      requestOptions
    );
    const data = await resp.json();
    setFormData(data);
    let uniqueParents = new Set(data.map((item) => ({label: item.name, value: item.id})));
    console.log("uniqueParents", uniqueParents);
    setParent([...uniqueParents]);
  };

  const [form] = Form.useForm();
  const setFormdata = () => {
    editData.name = editData?.name;
    editData.created_at = editData?.created_at;
    editData.parent = editData?.parent?.name;
    editData.status = editData?.status;
    if (editData?.created_at) {
      editData.created_at = moment(editData.created_at);
    }
    // console.log("Edit data", editData);
    form.setFieldsValue(editData);
  };

  const handleSave = () => {
    let _method = "post";
    let url = `${URL_WITH_VERSION}accounts/admin_list`;
    if (editData?.id) {
      _method = "put";
      url = `${URL_WITH_VERSION}accounts/admin_list`;
    }

    form
      .validateFields()
      .then((values) => {
        values["status"] = values["status"] ? 1 : 0;
        if (values["sub_id"]) values["sub_id"] = values["sub_id"]["value"];
        if (editData?.id) values["id"] = editData?.id;
        postAPICall(url, values, _method, (data) => {
          if (data.status == 1) {
            openNotificationWithIcon(
              "success",
              "Payment Details Are saved Successfully"
            );
            onCancel();
          } else {
            openNotificationWithIcon("error", data.msg);
          }
        });
        // form.resetFields();
        // onSave(values);
      })
      .catch((errorInfo) => {
        openNotificationWithIcon("error", errorInfo);
      });
  };

  // Set initial form values when in "edit" mode
  //   if (formData) {
  //     form.setFieldsValue(formData);
  //   }
  console.log("parent State", parent);
  return (
    <Modal
      title={
        <div style={{ textAlign: "center" }}>
          {editData?.id ? "Edit Menu List" : "Add Menu List"}
        </div>
      }
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={[
        <Button
          key="save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} style={formStyle} {...formItemLayout}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter the Name" }]}
            >
              <Input
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Parent Name"
              name="parent"
              rules={[
                {
                  required: true,
                  message: "Please enter the Parent Name",
                },
              ]}
            >
              <Select
                cacheOptions
                showSearch
                placeholder="Select Parent Name"
                style={{ width: "100%" }}
                options={parent}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Creation Date"
              name="created_at"
              rules={[
                {
                  required: true,
                  message: "Please enter the Creation Date",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col> */}
          {/* <Col xs={24} sm={6} md={6} lg={6} xl={6}>
            <Form.Item label="Status" name="status">
              <Switch defaultChecked={true} />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Modal>
  );
}

MenuListModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
};

export default MenuListModal;
