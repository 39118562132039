import { CircularProgress } from '@mui/material';
import { Input, Tooltip } from 'antd';
import { Card } from 'antd';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getAPICall } from 'shared';
import { openNotificationWithIcon } from 'shared';
import { URL_WITH_VERSION } from 'shared';

const HireRateList = () => {
  const [tableData, setTableData] = useState([]);
  const [pending, setPending] = useState(true);
  const [searchValue, setSearchValue] = useState('');


  const columns = [

    {
        label: 'Sender Email',
        name: 'Sender Email',
        selector: (row) => row.sender_email,
        sortable: true,
        // cell: (row) => row.date
        cell: (row) => row.sender_email,

    },
    {
        label: 'Sender Name',
        name: 'Sender Name',
        selector: (row) => row.sender_name,
        sortable: true,
        cell: (row) => row.sender_name

    },
    {
        name: 'Broker',
        selector: (row) => row.broker,
        sortable: true,
        cell: (row) => row.broker
    },
    // {
    //   name: 'Owner',
    //   selector: (row) => row.owner,
    //   sortable: true,
    //   cell: (row) => rowDataFormatter(row.owner)
    // },
    {
        name: 'Vessel Name',
        selector: (row) => row.vessel_name,
        sortable: true,
        cell: (row) => row.vessel_name

    },
    {
        name: 'CP Date',
        selector: (row) => row.cp_date,
        sortable: true,
        cell: (row) => row.cp_date
    },
    {
        name: 'Cargo Name',
        selector: (row) => row.cargo_name,
        sortable: true,
        cell: (row) => row.cargo_name
    },

    {
        name: 'Cargo Quantity',
        selector: (row) => row.cargo_quantity,
        sortable: true,
        cell: (row) => row.cargo_quantity
    },
    {
        label: 'Charterers',
        name: 'Charterers',
        selector: (row) => row.charterers,
        sortable: true,
        cell: (row) => row.charterers
    },
    {
        label: 'Owners',
        name: 'Owners',
        // selector: (row) => row.Sender,
        sortable: true,
        cell: (row) => row.owners

        //  <StatusCell row={row} />


        // cell: (row) => row.Sender.split('<')[0]
    },
    {
        name: 'Hire Rate',
        selector: (row) => row.hire_rate,
        sortable: true,
        cell: (row) => row.hire_rate
        //
    },
    // {
    //   name: 'Comments',
    //   selector: (row) => row.comments,
    //   sortable: true,
    //   cell: (row) => row.comments
    //   //
    // },

    {
        name: 'Delivery',
        selector: (row) => row.delivery,
        sortable: true,
        cell: (row) => row.delivery,
        width: '5rem'
    },

    {
        name: 'Redelivery',
        selector: (row) => row.redelivery,
        sortable: true,
        cell: (row) => row.redelivery
    },



]

const staticData = [
    {
      sender_email: "sender1@example.com",
      sender_name: "Sender 1",
      broker: "Broker 1",
      vessel_name: "Vessel 1",
      cp_date: "2024-05-15",
      cargo_name: "Cargo 1",
      cargo_quantity: 1000,
      charterers: "Charterers 1",
      owners: "Owners 1",
      hire_rate: 5000,
      delivery: "Delivery 1",
      redelivery: "Redelivery 1"
    },
    {
      sender_email: "sender2@example.com",
      sender_name: "Sender 2",
      broker: "Broker 2",
      vessel_name: "Vessel 2",
      cp_date: "2024-05-16",
      cargo_name: "Cargo 2",
      cargo_quantity: 2000,
      charterers: "Charterers 2",
      owners: "Owners 2",
      hire_rate: 6000,
      delivery: "Delivery 2",
      redelivery: "Redelivery 2"
    },
  ];
  

//   useEffect(() => {
//     getTableData();
//   }, []);

//   const getTableData = async () => {
//     setPending(true);
//     try {
//       const authtoken = localStorage.getItem('token');
//       const requestOptions = {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         redirect: 'follow',
//         body: JSON.stringify({ Mailtype: 'tonnage' }),
//       };

//       const resp = await fetch(
//         `${URL_WITH_VERSION}accounts/cargo-tonnage-mails`,
//         requestOptions,
//       );
//       const respdata = await resp.json();
//     //   setTableData(respdata?.emails);
//       setPending(false);
//     } catch (err) {
//       openNotificationWithIcon(
//         'error',
//         err.message || 'An error occurred while fetching table data.',
//       );
//     }
//   };

//   const filteredData = tableData.filter((row) => {
//     const values = Object.values(row).join(' ');
//     return values.toLowerCase().includes(searchValue.toLowerCase());
//   });

//   const handleSearch = (e) => {
//     setSearchValue(e.target.value);
//   };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox pt={6} pb={3}>
      <div style={{display: 'flex', alignItems: 'center', maxWidth: '250px'}}>
        {/* <label style={{whiteSpace: 'nowrap'}} htmlFor="search"><b>Search : &nbsp;</b></label> 
        <MDInput
            type="text"
            style={{minWidth: '200px'}}
            name='search'
            id='search'
            value={searchValue}
            onChange={handleSearch}
            label="Search here"
          />
        </div>
      </MDBox>
         */}
        <Card style={{ background: '#3c3c43' }} className='coming-soon-card'>
        <MDBox>
        
        <div className="waviy">
            <span style={{ '--i': 1 }}>c</span>
            <span style={{ '--i': 2 }}>o</span>
            <span style={{ '--i': 3 }}>m</span>
            <span style={{ '--i': 4 }}>i</span>
            <span style={{ '--i': 5 }}>n</span>
            <span style={{ '--i': 6 , marginRight: '20px'}}>g</span>
            <span style={{ '--i': 7 }}>s</span>
            <span style={{ '--i': 8 }}>o</span>
            <span style={{ '--i': 9 }}>o</span>
            <span style={{ '--i': 10 }}>n</span>
          </div>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};

export default HireRateList;
