// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import moment from 'moment';
import DataTable from 'react-data-table-component';

// Data
import { DeleteFilled, EditOutlined } from '@ant-design/icons';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Popconfirm, Tooltip } from 'antd';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import NewsSectionForm from 'layouts/newsSectionForm';
import { useEffect, useState } from 'react';
import { openNotificationWithIcon, URL_WITH_VERSION } from 'shared';

function NewsSectionList() {

  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: undefined,
    isView: false,
  });

  const [isAddNews, setIsAddNews] = useState(false);
  const [filterText, setfilterText] = useState('');
  const [pending, setPending] = useState(true);

  const filterdata =
    state?.rows?.filter((item) =>
      JSON.stringify(item)
        .toLocaleLowerCase()
        .includes(filterText.toLowerCase()),
    ) || [];

  const columns = [
    {
      name: <strong>ID</strong>,
      width: '80px',
      selector: (row) => (
        <span style={{ color: 'orange', fontWeight: '600' }}>{row?.id}</span>
      ),
      sortable: true,
    },
    {
      name: 'Title',
      selector: (row) => (
        <Tooltip title={row?.title}>
          <span>{row?.title}</span>
        </Tooltip>
      ),
      sortable: true,
    },
    // {
    //   name: 'News Body',
    //   selector: (row) => (
    //     <span
    //       style={{
    //         whiteSpace: 'nowrap',
    //         display: 'block',
    //         width: '140px',
    //         overflow: 'hidden',
    //         textOverflow: 'ellipsis',
    //       }}
    //     >
    //       {row?.details || '-'}
    //     </span>
    //   ),
    //   sortable: true,
    // },
    {
      name: 'Created By',
      selector: (row) => row?.created_by,
      sortable: true,
      center: true,
    },
    {
      name: 'Creation Date',
      selector: (row) => (
        <span style={{ color: 'navy', fontWeight: '600' }}>
          {moment(row?.created_at).format('DD-MM-YYYY HH:MM') || 'N/A'}
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Updation Date',
      selector: (row) => (
        <span style={{ color: 'navy', fontWeight: '600' }}>
          {moment(row?.updated_at).format('DD-MM-YYYY HH:MM') || 'N/A'}
        </span>
      ),
      sortable: true,
    },

    {
      name: 'Status',
      selector: (row) => row?.status,
      sortable: true,
      width: '120px',
      center: true,
      cell: (row) => {
        let status = row?.status;
        if (status == 1) {
          return <span className="status_tags Approvedtags">Active</span>;
        }
        return <span className="status_tags Processingtags">Deactive</span>;
      },
    },
    {
      name: 'Actions',
      center: true,
      cell: (row) => (
        <>
          <MDTypography
            component="a"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            <EditOutlined style={{marginRight: '16px'}} onClick={() => handleEdit(row)} />
          </MDTypography>

          <Popconfirm
            title="Delete the News"
            description="Are you sure to delete this news?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleNewsDelete(row.id)}
          >
            <DeleteFilled style={{color: 'red'}} />
          </Popconfirm> 
        </>
      ),
      right: true,
      width: '80px',
    },
  ];

  useEffect(() => {
    window.getNewsTableData();
  }, []);

   window.getNewsTableData = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/getting-news-letter-data`,
        requestOptions,
      );
      const respdata = await resp.json();

      if (respdata) {
        setState((prevState) => ({ ...prevState, rows: respdata }));
        setPending(false);
      } else {
        openNotificationWithIcon('error', 'Something went worng.');
      }
    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  };

  const handleNewsDelete = async(id) => {
    try {
      const requestOptions = {
        method: 'DELETE',
        redirect: 'follow',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/delete-news-letter-image-details/${id}`,
        requestOptions,
      );
      const respdata = await resp.json();

      if (respdata) {
        openNotificationWithIcon('success', respdata.msg);
        window.getNewsTableData();
      } else {
        openNotificationWithIcon('error', 'Something went worng.');
      }
    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  }

  const handleEdit = (row) => {
    setState((prevState) => ({ ...prevState, editData: row }));
    setIsAddNews(true);
  };

  const handleView = (row) => {
    setState((prevState) => ({ ...prevState, editData: row, isView: true }));
    setIsAddNews(true);
  };

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      editData: undefined,
      isView: false,
    }));
    setIsAddNews(false);
  };

  const { editData } = state;

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    minWidth: '200px',
                    maxWidth: '600px',
                  }}
                  onChange={(e) => setfilterText(e.target.value)}
                />
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddNews(true)}
                >
                  Add News
                </MDButton>
              </div>
              <br />
              <br />
              <Card>
                <MDBox>
                  <DataTable
                    columns={columns}
                    responsive
                    data={filterdata}
                    pagination
                    progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      {isAddNews === true ? (
        <NewsSectionForm
          visible={true}
          onCancel={onCancel}
          onSave={(value) => {
            console.log(value);
          }}
          editData={editData}
          isView={state?.isView}
        />
      ) : undefined}
    </>
  );
}

export default NewsSectionList;
