import {
  DeleteFilled,
  EditFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { CircularProgress } from '@mui/material';
import { Input, Modal, Popconfirm, Tooltip } from 'antd';
import { Card } from 'antd';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { openNotificationWithIcon } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import EditTonnage from './EditTonnage';
import { postAPICall } from 'shared';
import InfiniteScroll from 'react-infinite-scroll-component';

const TonnageList = () => {
  const [tableData, setTableData] = useState([]);
  const [pending, setPending] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [editData, setEditData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const columns = [
    {
      name: <strong>Action</strong>,
      width: '80px',
      cell: (row) => (
        <>
          <Tooltip title="Edit" placement="left">
            <span
              style={{ cursor: 'pointer' }}
              onClick={handleEditFunc(row)}
              className="ellipsis-text"
            >
              <EditFilled />
            </span>
          </Tooltip>
          <Tooltip title="Delete" placement="right">
            <Popconfirm
              title="Delete this Tonnage"
              description="Are you sure to delete this row?"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => deleteFunction(row['_id'])}
            >
              <DeleteFilled
                style={{ cursor: 'pointer', paddingLeft: '10px' }}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
    {
      name: <strong>Recieved Date</strong>,
      key: 'New_Date',
      cell: (row) => (
        <Tooltip title={row.New_Date + ' ' + row.Time}>
          <span className="ellipsis-text">{row.New_Date + ' ' + row.Time}</span>
        </Tooltip>
      ),
      width: '170px',
    },
    {
      name: <strong>Vessel Name</strong>,
      key: 'vessel_name',
      cell: (row) => (
        <Tooltip title={row.vessel_name}>
          <span className="ellipsis-text" style={{color: 'green', fontWeight: '700'}}>{row.vessel_name || "N/A"}</span>
        </Tooltip>
      ),
      width: '120px',
    },
    {
      name: <strong>Vessel Type</strong>,
      key: 'vessel_type',
      cell: (row) => row.vessel_type || 'N/A',
    },
    {
      name: <strong>DWT</strong>,
      key: 'dwt',
      cell: (row) => row.dwt && <span style={{color: 'deeppink', fontWeight: '700'}}>{row.dwt}</span> || 'N/A',
    },
    {
      name: <strong>Receiver</strong>,
      key: 'New_From',
      width: '120px',
      cell: (row) =>
        Array.isArray(row.New_From) && row.New_From.length > 0
          ? row.New_From.map((e, index) => (
              <Tooltip key={index} title={e}>
                <span className="ellipsis-text">{e}</span>
              </Tooltip>
            ))
          : 'N/A',
    },
    {
      name: <strong>Sender</strong>,
      key: 'client',
      cell: (row) => (
        <Tooltip title={row.Client}>
          <span className="ellipsis-text">{row.Client || 'N/A'}</span>
        </Tooltip>
      ),
      width: '120px',
    },
    {
      name: <strong>Open Date</strong>,
      key: 'open_date',
      cell: (row) => (
        <Tooltip title={row.open_date}>
          <span className="ellipsis-text">{row.open_date || 'N/A'}</span>
        </Tooltip>
      ),
    },
    {
      name: <strong>Open Area</strong>,
      key: 'open_area',
      cell: (row) => row.open_area || 'N/A',
    },
    {
      name: <strong>Build Year</strong>,
      key: 'build_year',
      cell: (row) => row.build_year || 'N/A',
      width: '80',
    },
    {
      name: <strong>BOD/BOR</strong>,
      key: 'bod',
      cell: (row) => (
        <Tooltip title={row.bod}>
          <span className="ellipsis-text">{row.bod ?? 'N/A'}</span>
        </Tooltip>
      ),
    },
    {
      name: <strong>Cranes</strong>,
      key: 'cranes',
      cell: (row) => (
        <Tooltip title={row.cranes}>
          <span className="ellipsis-text">{row.cranes ?? 'N/A'}</span>
        </Tooltip>
      ),
    },
  ];


  const handleEditFunc = (row) => () => {
    setEditData(row);
    setIsEditable(true);
  };

  const deleteFunction = async (id) => {
    setPending(true);
    try {
      const url = `${URL_WITH_VERSION}accounts/delete-cargo-tonnage-mails/tonnage/${id}`;
      const method = 'POST';

      await postAPICall(url, {}, method, (data) => {
        openNotificationWithIcon(
          'success',
          data?.msg || 'Data deleted successfully.',
        );
        resetTableData();
      });
    } catch (err) {
      openNotificationWithIcon(
        'error',
        err.message || 'An error occurred while deleting the data.',
      );
    }
    setPending(false);
  };

  useEffect(() => {
    loadMoreData(20, currentPage);
  }, []);

  const loadMoreData = async (size, page) => {
    setPending(true);
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        body: JSON.stringify({ Mailtype: 'tonnage',}),
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/cargo-tonnage-mails?pageSize=${size}&page=${page}`,
        requestOptions,
      );
      const respdata = await resp.json();

      if (respdata?.emails.length > 0) {
        setTableData(prev => [...prev, ...respdata.emails]);
        setCurrentPage(prev => prev + 1);
      } else {
        setHasMore(false);
      }
      
      setPending(false);
    } catch (err) {
      openNotificationWithIcon(
        'error',
        err.message || 'An error occurred while fetching table data.',
      );
      setPending(false);
    }
  };

  const resetTableData = () => {
    setTableData([]);
    setCurrentPage(1);
    setHasMore(true);
    loadMoreData();
  };

  const filteredData = tableData.filter((row) => {
    const values = Object.values(row).join(' ');
    return values.toLowerCase().includes(searchValue.toLowerCase());
  });

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <div
            style={{ display: 'flex', alignItems: 'center', maxWidth: '250px' }}
          >
            <MDInput
              type="text"
              style={{ minWidth: '200px' }}
              name="search"
              id="search"
              value={searchValue}
              onChange={handleSearch}
              label="Search here"
            />
          </div>
        </MDBox>

        <Card>
          <MDBox>
            <InfiniteScroll
              dataLength={filteredData.length}
              next={() => loadMoreData(20, currentPage + 1)}
              hasMore={hasMore}
              loader={<p style={{ textAlign: 'center', marginBlock: '16px' }}><b>Loading...</b></p>}
              endMessage={<p style={{ textAlign: 'center' }}>No more data to load.</p>}
            >
              <DataTable
                columns={columns}
                data={filteredData}
                pagination={false}
                progressPending={pending && currentPage === 1}
                progressComponent={<CircularProgress />}
                responsive
                highlightOnHover
                expandOnRowClicked
              />
            </InfiniteScroll>
          </MDBox>
        </Card>
      </DashboardLayout>
      <Modal
        open={isEditable}
        onCancel={() => setIsEditable(false)}
        title="Edit Tonnage"
        footer={null}
        width={800}
        className="EditTonnageModal"
      >
        <EditTonnage
          getTableData={resetTableData}
          setIsEditable={setIsEditable}
          editData={editData}
        />
      </Modal>
    </>
  );
};

export default TonnageList;
