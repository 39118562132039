// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

import DataTable from 'react-data-table-component';

// Data
import { useEffect, useState } from 'react';
import { URL_WITH_VERSION } from 'shared';
import { CircularProgress } from '@mui/material';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import AddModuleForm from 'layouts/addModuleForm';
import { openNotificationWithIcon } from 'shared';
import EditQuery from 'layouts/editQuery';
import moment from 'moment';

function CustomerQuery() {
  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: [],
    isView: false,
  });
  const [isAddCompany, setIsAddComapny] = useState(false);
  const [filterText, setfilterText] = useState('');
  const [pending, setPending] = useState(true);
  const [count, setcount] = useState(0);
  const [filterdata, setFilterdata] = useState([]);

  // const filterdata = state?.rows?.filter((item) =>
  //   JSON.stringify(item).toLocaleLowerCase().includes(filterText.toLowerCase()),
  // )||[];

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      editData: [],
      isView: false,
    }));
    setIsAddComapny(false);
    setcount(count + 1);
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row?.id || 'N/A',
      sortable: true,
      width: '60px',
      center: true,
    },
    {
      name: 'Company Name',
      selector: (row) => row?.company_name || 'N/A',
      sortable: true,
      center: true,
    },
    {
      name: 'Email Address',
      selector: (row) => row?.email || 'N/A',
      sortable: true,
      center: true,
    },
    {
      name: 'Priority Level',
      selector: (row) =>
        (row?.priority_level && (
          <span style={{ textTransform: 'uppercase' }}>
            {row?.priority_level}
          </span>
        )) ||
        'N/A',
      sortable: true,
      center: true,
    },
    {
      name: 'Subject',
      selector: (row) => row?.subject || 'N/A',
      sortable: true,
      center: true,
    },
    {
        name: 'Date',
        selector: (row) => row?.created && moment(row?.created).format('DD-MM-YYYY HH:mm') || 'N/A',
        sortable: true,
        center: true,
      },
    {
      name: 'Status',
      selector: (row) => getStatusText(row?.status),
      sortable: true, 
      center: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <EditOutlined onClick={() => handleEdit(row)} />
        </MDTypography>
      ),
      right: true,
      width: '80px',
      center: true,
    },
  ];

  useEffect(() => {
    getTableData();
  }, [count]);

  const getStatusText = (status) => {
    switch (status) {
      case '1':
        return <span className="status_tags Pendingtags">PENDING</span>;
      case '2':
        return <span className="status_tags Approvedtags">PROGRESS</span>;
      case '3':
        return <span className="status_tags Activetags">RESOLVED</span>;
      case '4':
        return <span className="status_tags Processingtags">REJECTED</span>;
      default:
        return 'N/A';
    }
  };
  

  const getTableData = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}voyage-estimate/customer-support-sa`,
        requestOptions,
      );

      const respdata = await resp.json();

      setFilterdata(respdata.data);
      if (respdata) {
        setState((prevState) => ({ ...prevState, rows: respdata.data }));
        setPending(false);
      } else {
        openNotificationWithIcon('error', 'Something went worng.');
      }
    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  };

  const handleEdit = (row) => {
    setState((prevState) => ({ ...prevState, editData: row, isView: true }));
    setIsAddComapny(true);
  };

  const { editData } = state;

  console.log('editData query', editData);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    minWidth: '200px',
                    maxWidth: '600px',
                  }}
                  onChange={(e) => {
                    setfilterText(e.target.value);
                    setFilterdata(
                      state?.rows?.filter((item) =>
                        JSON.stringify(item)
                          .toLocaleLowerCase()
                          .includes(filterText.toLowerCase()),
                      ),
                    );
                  }}
                />
                {/* <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddComapny(true)}
                >
                  Add Package
                </MDButton> */}
              </div>
              <br />
              <br />
              <Card>
                <MDBox key={count}>
                  <DataTable
                    columns={columns}
                    data={filterdata}
                    pagination
                    progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      {isAddCompany === true ? (
        <EditQuery
          visible={true}
          onCancel={onCancel}
          onSave={(value) => {
            console.log(value);
          }}
          editData={editData}
          isView={state?.isView}
        />
      ) : undefined}
    </>
  );
}

export default CustomerQuery;
