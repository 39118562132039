import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Switch,
  Select,
  theme,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { postAPICall } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import moment from 'moment';
import { openNotificationWithIcon } from 'shared';

const { Item } = Form;
const Option = Select.Option;

function AddPaymentForm({ visible, onCancel, editData }) {
  const [formData, setFormData] = useState(null);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const formItemLayout = {
    labelCol: { span: 24 }, // Change the span value as needed
    wrapperCol: { span: 24 }, // Change the span value as needed
  };

  const getCompanyList = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/company-details`,
        requestOptions,
      );
      const respdata = await resp.json();
      setCompanyList(respdata)
    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  };

  const { token } = theme.useToken();
  const formStyle = {
    maxWidth: 'none',
    background: token?.colorFillAlter,
    borderRadius: token?.borderRadiusLG,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  };

  formStyle.labelStyle = {
    flex: '1',
    maxWidth: '40%', // Adjust this value to control the maximum label width
    paddingRight: '10px', // Add spacing between label and input
    textAlign: 'right', // Right-align the label text
  };

  // Styles for input boxes
  formStyle.inputStyle = {
    flex: '1', // Allow input boxes to take up the remaining space
  };

  useEffect(() => {
    // const getCompanyData = async () => {
    //   if (editData?.id) {
    //     const requestOptions = {
    //       method: 'GET',
    //       redirect: 'follow',
    //     };
    //     const resp = await fetch(
    //       `${URL_WITH_VERSION}accounts/admin_list/${editData.id}`,
    //       requestOptions,
    //     );
    //     const data = await resp.json();
    //       setFormData(data);
    //   }
    // };
    // getCompanyData();
    getCompanyList();
    if(editData?.id){
      setFormdata()
    }
  }, []);

  const [form] = Form.useForm();
  const setFormdata = () => {
    editData.company = editData?.company.id;
    editData.product_name = editData?.product_name;
    editData.session_id = editData?.session_id;
    editData.total_amount = editData?.total_amount;
    editData.type = editData?.type;
    editData.status = editData?.status;
    if (editData?.expires_at) {
      editData.expires_at = moment(editData.expires_at);
    }
    form.setFieldsValue(editData);
  };

  const handleSave = () => {
    let _method = 'post';
    let url = `${URL_WITH_VERSION}accounts/paymentinfo-details`;
    if (editData?.id) {
      _method = 'put';
      url = `${URL_WITH_VERSION}accounts/paymentinfo-details`;
    }

    form
      .validateFields()
      .then((values) => {
        // values['status'] = values['status'] ? 1 : 0;
        // values['product_name'] = 'OceannVM';
        if (values['sub_id']) values['sub_id'] = values['sub_id']['value'];
        if (editData?.id) values['id'] = editData?.id;

        if (!editData?.id) {
          values['subscription_type'] = values['subscription_type'];
          values['number_of_users'] = values['number_of_users'];
        }
        postAPICall(url, values, _method, (data) => {
          if (data.status == 1) {
            openNotificationWithIcon(
              'success',
              data.msg,
            );
            onCancel()
          } else {
            openNotificationWithIcon('error', data.msg);
          }
        });
        // form.resetFields();
        // onSave(values);
      })
      .catch((errorInfo) => {
        openNotificationWithIcon('error', errorInfo.message); // Only display the error message
      });
      
  };

  // Set initial form values when in "edit" mode
  if (formData) {
    form.setFieldsValue(formData);
  }

  return (
    <Modal
      title={
        <div style={{ textAlign: 'center' }}>
          {editData?.id ? 'Edit Payment' : 'Add Payment'}
        </div>
      }
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={[
        <Button
          key="save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} style={formStyle} {...formItemLayout}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Company Name"
              name="company"
              rules={[
                {
                  required: true,
                  message: 'Please enter the Comapany Name',
                },
              ]}
            >
              <Select
                cacheOptions
                defaultOptions
                disabled={editData?.id ? true : false}
                placeholder="Select Comapany Name"
                style={{ width: '100%' }}
                options={
                  companyList.map((e) => ({
                    value: e.id,
                    label: e.company_name,
                  }))
                }
                // onChange={forproductName}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Product Name"
              name="product_name"
               rules={[
                 {
                   required: true,
                   message: 'Please enter the Product Name',
                 },
               ]}
            >
              <Select
                cacheOptions
                defaultOptions
                placeholder="Select Product Name"
                style={{ width: '100%' }}
                options={
                 [{
                  label: 'OceannMail',
                  value: 'OceannMail'
                 },
                 {
                  label: 'OceannVM',
                  value: 'OceannVM'
                 }]
                }
              /> 

              {/* <Input defaultValue={"OceannVM"} disabled style={{color: 'black'}} /> */}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Session Id"
              name="session_id"
              rules={[
                { required: true, message: 'Please enter the Session id' },
              ]}
            >
              <Input disabled={editData?.id ? true : false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Amount"
              name="total_amount"
              rules={[
                { required: true, message: 'Please enter the Amount' },
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Payment Method"
              name="type"
              rules={[{ required: true, message: 'Please enter the Payment Method' }]}
            >
              <Select placeholder="Payment Method" style={{ width: '100%' }}>
                <Option key="cash" value="cash">
                  Cash
                </Option>
                <Option key="cheque" value="cheque">
                  Cheque
                </Option>
                <Option key="online" value="online">
                  Online
                </Option>
              </Select>
            </Form.Item>
          </Col>
          {/**
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Expiry Date"
              name="expires_at"
              rules={[
                {
                  required: true,
                  message: 'Please enter the Expiry Date',
                },
              ]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
             */}
          
          {!editData?.id &&(
            <>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Subscription Type"
              name="subscription_type"
              rules={[{ required: true, message: 'Please select your subscription type' }]}
            >
              <Select placeholder="Subscription type" style={{ width: '100%' }}>
                <Option key="yearly" value="yearly">
                  yearly
                </Option>
                <Option key="monthly" value="monthly">
                  monthly
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="No of users"
              name="number_of_users"
              rules={[
                { required: true, message: 'Please enter no of users' },
              ]}
            >
              <Input  style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          </>
          )}
          
          <Col xs={24} sm={6} md={6} lg={6} xl={6}>
              <Form.Item label="Status" name="payment_status">
                <Switch defaultChecked={true} />
              </Form.Item>
            </Col>
        </Row>
      </Form>
    </Modal>
  );
}

AddPaymentForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.object.isRequired,
};

export default AddPaymentForm;
