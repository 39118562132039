import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Row, Col, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { toast } from 'react-toastify';
import { URL_WITH_VERSION, postAPICall, openNotificationWithIcon } from 'shared';

const EditCargo = ({ editData, getTableData, setIsEditable, onDelete }) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});

  const fieldConfig = [
    { name: 'cargo', label: 'Cargo Name', placeholder: 'Cargo Name', editable: true },
    { name: 'cargo_type', label: 'Cargo Type', placeholder: 'Cargo Type', editable: true },
    { name: 'company', label: 'Company Name', placeholder: 'Company Name', editable: true },
    { name: 'cargo_size', label: 'Cargo Size', placeholder: 'Cargo Size', editable: true },
    { name: 'laycan', label: 'Laycan', placeholder: 'Laycan', editable: true },
    { name: 'load_port', label: 'Load Port', placeholder: 'Load Port', editable: true },
    { name: 'duration', label: 'Duration', placeholder: 'Duration', editable: true },
    { name: 'discharge_port', label: 'Discharge Port', placeholder: 'Discharge Port', editable: true },
    { name: 'discharge_rate', label: 'Discharge Rate', placeholder: 'Discharge Rate', editable: true },
  ];

  const getValueOrDefault = (value) =>
    value !== null && value !== undefined ? value : 'N/A';

  useEffect(() => {
    const values = {};
    fieldConfig.forEach(({ name }) => {
      if (name === 'load_port' || name === 'discharge_port') {
        values[name] = getValueOrDefault(editData[name]?.port);
      } else {
        values[name] = getValueOrDefault(editData[name]);
      }
    });
    form.setFieldsValue(values);
    setInitialValues(values);
  }, [editData, form]);

  const convertToAppropriateType = (key, value) => {
    const numericFields = ['cargo_size'];
    return numericFields.includes(key) ? Number(value) : value;
  };

  const isFieldChanged = (values, initialValues) => {
    return fieldConfig.some(({ name, editable }) => {
      if (!editable) return false;
      const currentValue = convertToAppropriateType(name, values[name]);
      const initialValue = convertToAppropriateType(name, initialValues[name]);
      return currentValue !== initialValue;
    });
  };

  const onFinish = async (values) => {
    values['_id'] = editData['_id'];
    values['cargo_size'] = Number(values['cargo_size']);

    if (isFieldChanged(values, initialValues)) {
      const url = `${URL_WITH_VERSION}accounts/update-cargo-tonnage-mails/cargo`;
      const method = 'POST';

      // Create the loadPort and dischargePort objects
    const loadPort = {
      port: values['load_port'],
      country: editData.load_port?.country || 'N/A',
      region: editData.load_port?.region || 'N/A',
    };

    const dischargePort = {
      port: values['discharge_port'],
      country: editData.discharge_port?.country || 'N/A',
      region: editData.discharge_port?.region || 'N/A',
    };

    // Prepare the values to be sent in the API call
    const payload = {
      ...values,
      load_port: loadPort,
      discharge_port: dischargePort,
    };


      try {
        await postAPICall(url, payload, method, (data) => {
          if (data) {
            openNotificationWithIcon('success', 'Cargo updated successfully');
            getTableData();
            setIsEditable(false);
          } else {
            openNotificationWithIcon('error', 'Something went wrong!! Please try again.');
          }
        });
        
      } catch (err) {
        console.error('Error:', err);
        toast.error('Error while updating cargo');
      }
    } else {
      openNotificationWithIcon('info', "You haven't changed anything. Please change some values to update.");
    }
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={8}>
          {fieldConfig.map(({ name, label, editable, placeholder }) => (
            <Col sm={8} key={name}>
              <Form.Item name={name} label={label}>
                <Input placeholder={placeholder} disabled={!editable} />
              </Form.Item>
            </Col>
          ))}
          <Col sm={24} style={{ textAlign: 'right' }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

EditCargo.propTypes = {
  editData: PropTypes.object.isRequired,
  getTableData: PropTypes.func.isRequired,
  setIsEditable: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default EditCargo;
