import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Switch,
  Select,
  theme,
  DatePicker,
} from "antd";
import MultipleSelect, { StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";
import { SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { postAPICall } from "shared";
import { URL_WITH_VERSION } from "shared";
import moment from "moment";
import { openNotificationWithIcon } from "shared";

const { Item } = Form;
const { Option } = Select;

function EditUser({
  visible,
  onCancel,
  editMode,
  editData,
  isView,
}) {
  const formItemLayout = {
    labelCol: { span: 24 }, // Change the span value as needed
    wrapperCol: { span: 24 }, // Change the span value as needed
  };

  const [subscriberData, setsubscriberData] = useState([]);
  const [count, setCount] = useState(0);
  const [expiryDate, setExpiryDate] = useState(null);
  const [roleList, setRoleList] = useState([]);

  // const loadOptions = async () => {
  //   try {
  //     const requestOptions = {
  //       method: 'GET',
  //       redirect: 'follow',
  //     };

  //     const resp = await fetch(
  //       `${URL_WITH_VERSION}accounts/modules-details`,
  //       requestOptions,
  //     );
  //     const respdata = await resp.json();
  //     if (respdata) {
  //       const data = respdata.map((e, i) => {
  //         return { value: e.module_name, label: e.module_name };
  //       });
  //       return data;
  //     } else {
  //       openNotificationWithIcon('error', 'Something went worng.');
  //     }
  //   } catch (err) {
  //     openNotificationWithIcon('error', err);
  //   }
  // };
  // const companyOptions = async () => {
  //   try {
  //     const requestOptions = {
  //       method: 'GET',
  //       redirect: 'follow',
  //     };

  //     const resp = await fetch(
  //       `${URL_WITH_VERSION}accounts/company-details`,
  //       requestOptions,
  //     );
  //     const respdata = await resp.json();
  //     console.log('company Detail-->',respdata)
  //     if (respdata) {
  //       const data = respdata.map((e, i) => {
  //         return { value: e.id, label: e.company_name };
  //       });
  //       return data;
  //     } else {
  //       openNotificationWithIcon('error', 'Something went worng.');
  //     }
  //   } catch (err) {
  //     openNotificationWithIcon('error', err);
  //   }
  // };

  const { token } = theme.useToken();
  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    display: "flex",
    flexDirection: "column",
  };

  useEffect(() => {
    setFormdata();
    // getCompanyData();
  }, [count]);

  useEffect(()=>{
    getRoleList();
  },[])

  const [form] = Form.useForm();
  const setFormdata = () => {
    editData.email = editData?.email;
    editData.password = editData?.password;
    editData.role = editData?.role;
    editData.status = editData?.status;
    editData.company_name = editData?.company_id?.company_name;
    editData.comapny_status = editData?.company_id?.status;
    editData.phone_num = editData?.phone_number
      ? editData?.phone_number
      : "Not Available";
    form.setFieldsValue(editData);
  };

  // const getCompanyData = async () => {
  //   if (editData?.id) {
  //     const requestOptions = {
  //       method: 'GET',
  //       redirect: 'follow',
  //     };
  //     const resp = await fetch(
  //       `${URL_WITH_VERSION}accounts/subscriber/${editData.id}`,
  //       requestOptions,
  //     );
  //     const data = await resp.json();
  //     if (data) {
  //       data['expiry_date'] = data['expiry_date']
  //         ? moment(data['expiry_date'])
  //         : moment();
  //       data['status'] = data['status'] ? true : false;
  //       data['plan_id'] = [
  //         {
  //           value: data['plan_id']['id'],
  //           label: data['plan_id']['plan_name'],
  //         },
  //       ];
  //       form.setFieldsValue(data);
  //     } else {
  //       openNotificationWithIcon('error', 'Something went worng.');
  //     }
  //   }
  // };

  const getRoleList = async () => {
    try {
      const roleListData = await fetch(
        `${URL_WITH_VERSION}accounts/role-details`,
        {method:"POST",
        headers:{
          "Content-Type": "application/json"
        } ,
        body: JSON.stringify({
          company_id: editData?.company_id.id
        })}
      );
      let roleList = await roleListData.json();
      console.log("roleList-->>>", roleList);
      if (setRoleList) {
        setRoleList(roleList);
      }
    } catch (err) {
      console.log("error while fetching", err);
    }
  };
  const handleSave = () => {
    let _method = "post";
    if (editData?.id) {
      _method = "put";
    }
    form
      .validateFields()
      .then((values) => {
        values["status"] = values["status"] ? 1 : 0;
        values["id"] = editData?.id;
        if (values["company_id"])
          values["company_id"] = values["company_id"]["value"];
        postAPICall(
          `${URL_WITH_VERSION}accounts/update-user`,
          values,
          _method,
          (data) => {
            if (data.status === "1") {
              openNotificationWithIcon(
                "success",
                "User Details Are saved Successfully"
              );
              setCount(count + 1);
              onCancel();
            } else {
              openNotificationWithIcon("error", data.msg);
            }
          }
        );
        // form.resetFields();
        // onSave(values);
      })
      .catch((errorInfo) => {
        openNotificationWithIcon("error", "Plese Fill All Mandatory Fields");
      });
  };

  return (
    <Modal
      title={
        <div style={{ textAlign: "center" }}>
          {editData?.id ? `Edit User of ${editData?.company_id?.company_name}` : "Add Subscription"}
        </div>
      }
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={[
        <Button
          key="save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <fieldset style={{ border: "none" }}>
        <input type="hidden" value={count} />
        <Form form={form} {...formItemLayout} style={formStyle}>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item label="Email" name="email">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item label="Password" name="password">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item label="Role" name="role">
                <Select 
                showSearch
                options={roleList.map((role) => ({
                  value: role.label,
                  label: role.label,
                }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item label="User Phone No." name="phone_num">
                <Input placeholder={editData.phone_num} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item label="Company Name" name="company_name">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
              <Form.Item label="User Status" name="status">
                <Switch defaultChecked />
              </Form.Item>
            </Col>
            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
              <Form.Item label="Company Status" name="company_status">
                <Switch disabled defaultChecked />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </fieldset>
    </Modal>
  );
}

EditUser.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  editMode: PropTypes.func.isRequired,
  isView: PropTypes.bool.isRequired,
};

export default EditUser;
