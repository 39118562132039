import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Button } from 'antd';

const ContactForm = (props) => {
  const[id,setid]=useState(props.formData._id)
  const[oldstate,setOldState]=useState({
    name: props.formData.name || '',
    emails: props.formData.email || '',
    phone_numbers: props.formData.phone || '',
    skype: props.formData.skype || '',
    website: props.formData.website || '',
    company_name: props.formData.company || '',

  })
  const [state, setState] = useState({
    name: props.formData.name || '',
    emails: props.formData.email || '',
    phone_numbers: props.formData.phone || '',
    skype: props.formData.skype || '',
    website: props.formData.website || '',
    company_name: props.formData.company || '',
    
  });

  useEffect(() => {
    console.log("props",props)
    setState((prevState) => ({
      ...prevState,
      name: props.formData.name || '',
      emails: props.formData.email || '',
      phone_numbers: props.formData.phone || '',
      skype: props.formData.skype || '',
      website: props.formData.website || '',
      company_name: props.formData.company || '',
    }));
    setOldState((prevState) => ({
      ...prevState,
      name: props.formData.name || '',
      emails: props.formData.email || '',
      phone_numbers: props.formData.phone || '',
      skype: props.formData.skype || '',
      website: props.formData.website || '',
      company_name: props.formData.company || '',
    }));
  }, []);

  

  const handleChange = (field) => (e) => {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [field]: value
    }));
  };


  const isStateChanged = () => {
    return Object.keys(state).some((key) => state[key] !== oldstate[key]);
  };

  const handleUpdateForm=async()=>{
    if (!isStateChanged()) {
      alert("No changes detected, please update something.");
      return;
    }
    try {
      const url = `${process.env.REACT_APP_URL}accounts/update-vessel-data/${id}`;
      const data = {
        oldData: oldstate,
        newData: state
      };
      const request = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      const resp = await fetch(url, request);
      const response = await resp.json();

      console.log('response', response);
      if(response.status==true){
        props?.getTableData(1)
        props?.onclose()
      }else{
        console.log("some error occured")
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
    
      <Grid
        container
        justifyContent={'space-between'}
        style={{ margin: '20px 0 20px 0' }}
      >
        <Box width={'49%'}>
          <InputLabel htmlFor="my-input">
            User Name
            <Typography style={{ color: 'red', display: 'inline' }}>
              *
            </Typography>
          </InputLabel>

          <TextField
            value={state.name}
            name="name"
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleChange('name')}
          />
        </Box>

        <Box width={'49%'}>
          <InputLabel htmlFor="my-email">
            Company Name
            <Typography style={{ color: 'red', display: 'inline' }}>
              *
            </Typography>
          </InputLabel>

          <TextField size="small" name="company_name" fullWidth value={state.company_name} onChange={handleChange('company_name')} />
        </Box>
      </Grid>

      <Grid
        container
        justifyContent={'space-between'}
        style={{ margin: '20px 0 20px 0' }}
      >
        <Box width={'49%'}>
          <InputLabel htmlFor="my-input">Phone number</InputLabel>

          <TextField
            name="phone_numbers"
            value={state.phone_numbers}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleChange('phone_numbers')}
          />
        </Box>

        <Box width={'49%'}>
          <InputLabel htmlFor="my-input">Email Id</InputLabel>

          <TextField
            name="emails"
            value={state.emails}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleChange('emails')}
          />
        </Box>
      </Grid>

      <Grid
        container
        justifyContent={'space-between'}
        style={{ margin: '20px 0 20px 0' }}
      >
        <Box width={'49%'}>
          <InputLabel htmlFor="my-input">Website</InputLabel>

          <TextField
            name="website"
            value={state.website}
            type="email"
            size="small"
            fullWidth
            onChange={handleChange('website')}
          />
        </Box>

        <Box width={'49%'}>
          <InputLabel htmlFor="my-input">Skype Id</InputLabel>

          <div>
            <TextField
              fullWidth
              // defaultCountry={'us'}
              name="skype"
              value={state.skype}
              variant="outlined"
              size="small"
              className="contact-phone-input"
              onChange={handleChange('skype')}
            />
          </div>
        </Box>
      </Grid>
      <Grid container justifyContent={'flex-end'} style={{ marginTop: '20px' }}><Button onClick={handleUpdateForm} variant="contained" color="primary">Update</Button></Grid>
    </div>
  );
};

ContactForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  skype: PropTypes.string,
  website: PropTypes.string,
  company: PropTypes.string,
  formData: PropTypes.object ,// adjust the type according to your actual data structure
  getTableData: PropTypes.func.isRequired,
  onclose:PropTypes.func.isRequired
};

ContactForm.defaultProps = {
  id:"",
  name: '',
  email: '',
  phone: '',
  skype: '',
  website: '',
  company: '',
  getTableData: () => {}, // Default function
  onclose:()=>{}
};

export default ContactForm;
