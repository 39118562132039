import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Switch,
  Select,
  theme,
  DatePicker,
} from "antd";
import MultipleSelect, { StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";
import { SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { postAPICall } from "shared";
import { URL_WITH_VERSION } from "shared";
import moment from "moment";
import { openNotificationWithIcon } from "shared";

const { Item } = Form;
const { Option } = Select;

function EditRole({
  visible,
  onCancel,
  editMode,
  editData,
  isView,
}) {
  const formItemLayout = {
    labelCol: { span: 24 }, // Change the span value as needed
    wrapperCol: { span: 24 }, // Change the span value as needed
  };

  const [count, setCount] = useState(0);
  const [roleList, setRoleList] = useState([]);
  const [companyList, setCompanyList] = useState([]);


  // const loadOptions = async () => {
  //   try {
  //     const requestOptions = {
  //       method: 'GET',
  //       redirect: 'follow',
  //     };

  //     const resp = await fetch(
  //       `${URL_WITH_VERSION}accounts/modules-details`,
  //       requestOptions,
  //     );
  //     const respdata = await resp.json();
  //     if (respdata) {
  //       const data = respdata.map((e, i) => {
  //         return { value: e.module_name, label: e.module_name };
  //       });
  //       return data;
  //     } else {
  //       openNotificationWithIcon('error', 'Something went worng.');
  //     }
  //   } catch (err) {
  //     openNotificationWithIcon('error', err);
  //   }
  // };
  // const companyOptions = async () => {
  //   try {
  //     const requestOptions = {
  //       method: 'GET',
  //       redirect: 'follow',
  //     };

  //     const resp = await fetch(
  //       `${URL_WITH_VERSION}accounts/company-details`,
  //       requestOptions,
  //     );
  //     const respdata = await resp.json();
  //     console.log('company Detail-->',respdata)
  //     if (respdata) {
  //       const data = respdata.map((e, i) => {
  //         return { value: e.id, label: e.company_name };
  //       });
  //       return data;
  //     } else {
  //       openNotificationWithIcon('error', 'Something went worng.');
  //     }
  //   } catch (err) {
  //     openNotificationWithIcon('error', err);
  //   }
  // };

  const { token } = theme.useToken();
  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    display: "flex",
    flexDirection: "column",
  };

  useEffect(() => {
    setFormdata();
    // getCompanyData();
  }, [count]);

  useEffect(()=>{
    getRoleList();
    getCompanyList()
  },[])

  const [form] = Form.useForm();
  const setFormdata = () => {
    editData.name = editData?.name;
    editData.read = editData?.read;
    editData.value = editData?.value;
    editData.write = editData?.write;
    editData.label = editData?.label;
    form.setFieldsValue(editData);
  };

  const getCompanyList = async () => {
    const companyResp = await fetch(
        `${URL_WITH_VERSION}accounts/company-details`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const companylist = await companyResp.json();
      setCompanyList(companylist)
  }

  const getRoleList = async () => {
    try {
      const roleListData = await fetch(
        `${URL_WITH_VERSION}accounts/role-details`,
        {method:"POST",
        headers:{
          "Content-Type": "application/json"
        } ,
        body: JSON.stringify({
          company_id: editData?.company_id.id
        })}
      );
      let roleList = await roleListData.json();
      console.log("roleList-->>>", roleList);
      if (setRoleList) {
        setRoleList(roleList);
      }
    } catch (err) {
      console.log("error while fetching", err);
    }
  };
  const handleSave = () => {
    let _method = "post";
    if (editData?.id) {
      _method = "put";
    }
    form
      .validateFields()
      .then((values) => {
        values["status"] = values["status"] ? 1 : 0;
        values["id"] = editData?.id;
        if (values["company_id"])
          values["company_id"] = values["company_id"]["value"];
        postAPICall(
          `${URL_WITH_VERSION}accounts/update-role`,
          values,
          _method,
          (data) => {
            if (data.status === "1") {
              openNotificationWithIcon(
                "success",
                "User Details Are saved Successfully"
              );
              onCancel();
            } else {
              openNotificationWithIcon("error", data.msg);
            }
          }
        );
        // form.resetFields();
        // onSave(values);
      })
      .catch((errorInfo) => {
        openNotificationWithIcon("error", "Plese Fill All Mandatory Fields");
      });
  };

  return (
    <Modal
      title={
        <div style={{ textAlign: "center" }}>
          {editData?.id ? `Edit Role of ${editData?.name}` : "Edit Role"}
        </div>
      }
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={[
        <Button
          key="save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <fieldset style={{ border: "none" }}>
        <input type="hidden" value={count} />
        <Form form={form} {...formItemLayout} style={formStyle}>
          <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Company Name"
                name="company_name"
              >
                <Select 
                    showSearch
                    disabled
                    placeholder={`${editData?.company}`}
                    options={
                    companyList.map((cname) => ({
                        value: cname?.id,
                        label: cname?.company_name
                    }))
                    }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item label="Name" name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item label="Value" name="value">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item label="Label" name="label">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
              <Form.Item label="Write" name="write">
                <Switch  defaultChecked />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </fieldset>
    </Modal>
  );
}

EditRole.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  editMode: PropTypes.func.isRequired,
  isView: PropTypes.bool.isRequired,
};

export default EditRole;
