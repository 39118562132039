import { CircularProgress } from '@mui/material';
import { Button, Card, Modal, Space, Tooltip, Typography } from 'antd';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import { openNotificationWithIcon } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import VesselForm from './VesselForm';

const VesselList = () => {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [vesselFormData, setVesselFotmData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null);

  const columns = [
    {
      title: <strong>Vessel Name</strong>,
      render: (row) => (
        <Tooltip title={row.vessel_name}>
          <span className="ellipsis-text">{row.vessel_name ?? 'N/A'}</span>
        </Tooltip>
      ),
    },
    {
      title: <strong>Vessel Code</strong>,
      render: (row) => (
        <Tooltip title={row.vessel_code}>
          <span className="ellipsis-text">{row.vessel_code ?? 'N/A'}</span>
        </Tooltip>
      ),
    },

    {
      title: <strong>IMO No.</strong>,
      render: (row) => (
        <Tooltip title={row.imo_no}>
          <span className="ellipsis-text">{row.imo_no ?? 'N/A'}</span>
        </Tooltip>
      ),
    },
    {
      title: <strong>Vessel Type</strong>,
      render: (row) => (
        <Tooltip title={row.vessel_type}>
          <span className="ellipsis-text">
            {row.vessel_type && row.vessel_type.length > 15
              ? `${row.vessel_type.slice(0, 15)}...`
              : row.vessel_type || 'N/A'}
          </span>
        </Tooltip>
      ),
    },
    {
      title: <strong>Vessel Sub Type</strong>,
      render: (row) => (
        <Tooltip title={row.vessel_sub_type}>
          <span className="ellipsis-text">
            {row.vessel_sub_type && row.vessel_sub_type.length > 15
              ? `${row.vessel_sub_type.slice(0, 15)}...`
              : row.vessel_sub_type || 'N/A'}
          </span>
        </Tooltip>
      ),
    },

    {
      title: <strong>Summer DWT</strong>,
      render: (row) => (
        <Tooltip title={row.summer_dwt}>
          <span className="ellipsis-text">{row.summer_dwt ?? 'N/A'}</span>
        </Tooltip>
      ),
    },
    {
      title: <strong>No. of Cranes</strong>,
      render: (row) => (
        <Tooltip title={row.no_of_cranes}>
          <span className="ellipsis-text">
            {row.no_of_cranes ? row.no_of_cranes : 'N/A'}
          </span>
        </Tooltip>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      // fixed: 'right',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="edit contact">
            <div
              onClick={() => handleEdit(record)}
              style={{ cursor: 'pointer' }}
            >
              <EditOutlined />
            </div>
          </Tooltip>
          <Tooltip title="delete contact">
            <div>
              <DeleteOutlined
                onClick={() => handleDelete(record)}
                style={{ cursor: 'pointer', color: 'red' }}
              />
            </div>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleDelete = (record) => {
    setOpenDelete(true);
    setId(record.id);
  };
  const handleEdit = (record) => {
    setVesselFotmData(record);
    setOpenModal(true);
  };
  // console.log("hdjhjhjh",vesselFormData)
  useEffect(() => {
    getTableData();
  }, []);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const getTableData = async () => {
    try {
      const authtoken = localStorage.getItem('token');
      const requestOptions = {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow', // Include the request body
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/get-vessels`,
        requestOptions,
      );
      const respdata = await resp.json();
      setTableData(respdata.data);
      setFilteredData(respdata.data);

      setLoading(false);
    } catch (err) {
      openNotificationWithIcon(
        'error',
        err.message || 'An error occurred while fetching table data.',
      );
    }
  };

  useEffect(() => {
    if (searchValue === '') {
      // If search value is empty, revert to original data
      setFilteredData(tableData);
      // setTotalRecords(tableData.length);
    } else {
      const filtered = filteredData.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchValue.toLowerCase()),
        ),
      );
      setFilteredData(filtered);
      // setTotalRecords(filtered.length);
      setCurrentPage(1); // Reset to first page on search
    }
  }, [searchValue, tableData]);


  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const onclose = () => {
    setOpenModal(false);
  };

  const deleteContact = async () => {
    try {
      const url = `${process.env.REACT_APP_URL}accounts/delete-vessel/${id}`;
      const request = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const resp = await fetch(url, request);
      const response = await resp.json();
      setOpenDelete(false)
      if (response.status == 1) {
        openNotificationWithIcon('success', 'Contact deleted successfully');
        getTableData();
      }else{
        console.log("some error occured")
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <div
          style={{ display: 'flex', alignItems: 'center', maxWidth: '250px' }}
        >
          <MDInput
            type="text"
            style={{ minWidth: '200px' }}
            name="search"
            id="search"
            value={searchValue}
            onChange={handleSearch}
            label="Search here"
          />
        </div>
      </MDBox>

      <Card>
        <MDBox>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination
            onChange={handleTableChange}
            loading={loading}
          />
        </MDBox>
      </Card>

      {openModal ? (
        <Modal footer={null} open={openModal} onCancel={onclose}>
          <VesselForm
            onclose={onclose}
            getTableData={getTableData}
            formData={vesselFormData}
          />
        </Modal>
      ) : null}

      {openDelete ? (
        <Modal
          footer={null}
          open={openDelete}
          onCancel={() => setOpenDelete(false)}
        >
          <Typography>Are you sure you want to delete this contact?</Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button>Cancel</Button>
            <Button onClick={deleteContact}>Ok</Button>
          </div>
        </Modal>
      ) : null}
    </DashboardLayout>
  );
};

export default VesselList;
