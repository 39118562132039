// ImportOption.js
import { CircularProgress } from '@mui/material';
import { IconPencil } from '@tabler/icons-react';
import { Button, Card, Form, Input, Modal, Select, Switch } from 'antd';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { openNotificationWithIcon, URL_WITH_VERSION } from 'shared';

const ImportOption = () => {
  const [tableData, setTableData] = useState([]);
  const [pending, setPending] = useState(true);
  const [companyList, setCompanyList] = useState([]);
  const [listData, setListData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const authtoken = localStorage.getItem('token');
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const updateSwitchStatus = async (companyId) => {
    try {
      const response = await fetch(`${URL_WITH_VERSION}accounts/update-import-enable-disable/${companyId}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authtoken,
        },
      });

      const data = await response.json();

      if (response.ok) {
        openNotificationWithIcon("success", data.msg);
        getTableData()
      }
    } catch (error) {
      console.error('Error updating switch status:', error);
    }
  };

  const columns = [
    {
      name: <strong>Company Name</strong>,
      sortable: true,
      center: 'true',
      cell: (row) => row?.company?.company,
    },
    {
      name: <strong>ID</strong>,
      center: 'true',
      width: '80px',
      cell: (row) => row?.company?.id,
    },
    {
      name: <strong>Number of Options</strong>,
      center: 'true',
      cell: (row) => row?.emailimportoption?.length,
    },
    {
      name: <strong>Allow Import</strong>,
      center: 'true',
      width: '120px',
      cell: (row) => <Switch checked={row?.company?.import_enable_disable} onChange={() => updateSwitchStatus(row?.company?.id)} />,
    },
    {
      name: <strong>Options</strong>,
      center: 'true',
      cell: (row) =>
        row?.emailimportoption?.length > 0 ? (
          <div className="importOptionRow">
            {row?.emailimportoption.map((item) => (
              <span className="importOptionsTag" key={item.values}>
                {item?.label}
              </span>
            ))}
          </div>
        ) : (
          <span className="importOptionsTag">All Disabled</span>
        ),
    },
    {
      name: <strong>Action</strong>,
      center: 'true',
      width: '80px',
      cell: (row) => <IconPencil onClick={() => handleEdit(row)} />,
    },
  ];

  const handleEdit = (row) => {
    setSelectedCompany(row);
    form.setFieldsValue({
      company: row.company,
      emailimportoption: row.emailimportoption.map((item) => item.values), // Ensure correct format for multi-select
    }); // Set form values when opening the edit modal
    setEditModal(true);
  };

  const handleAdd = () => {
    form.resetFields(); // Reset form fields
    setAddModal(true);
  };

  useEffect(() => {
    getTableData();
    getCompanyList();
  }, []);

  const getTableData = async () => {
    setPending(true);
    try {
      const requestOptions = {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authtoken,
        },
        redirect: 'follow',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/get-all-labels`,
        requestOptions,
      );
      const respdata = await resp.json();

      setTableData(respdata.data); // Updated to properly set table data
      setListData(respdata.data); // Updated to properly set list data

      setPending(false);
    } catch (err) {
      openNotificationWithIcon(
        'error',
        err.message || 'An error occurred while fetching table data.',
      );
      setPending(false);
    }
  };

  const getCompanyList = async () => {
    setPending(true);
    try {
      const requestOptions = {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
      };
      const url = `${URL_WITH_VERSION}accounts/company-details`;
      const resp = await fetch(url, requestOptions);
      const respdata = await resp.json();

      setCompanyList(respdata);

      setPending(false);
    } catch (err) {
      openNotificationWithIcon(
        'error',
        err.message || 'An error occurred while fetching company list.',
      );
      setPending(false);
    }
  };

  const handleSubmit = async (values) => {
    setPending(true);
    try {
      const authtoken = localStorage.getItem('token');
      const requestOptions = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authtoken,
        },
        body: JSON.stringify(values),
        redirect: 'follow',
      };
      const url = `${URL_WITH_VERSION}accounts/update-labels-values`;
      const resp = await fetch(url, requestOptions);
      const respdata = await resp.json();
      openNotificationWithIcon('success', 'Labels updated successfully.');
      getTableData();
      setEditModal(false);
      setAddModal(false);
      setPending(false);
      form.resetFields();
      form2.resetFields();
    } catch (e) {
      openNotificationWithIcon(
        'error',
        e.message || 'An error occurred while updating labels.',
      );
      setPending(false);
    }
  };

  const handleSave = () => {
    form2
      .validateFields()
      .then((values) => {
        const obj = {};
        obj.company = values.company.id;
        obj.emailimportoption = values.emailimportoption;
        handleSubmit(obj);
      })
      .catch((error) => {
        console.error('Validation failed:', error);
      });
  };

  const handleEditSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const obj = {};
        obj.company = values.company.id;
        obj.emailimportoption = values.emailimportoption;
        handleSubmit(obj);
      })
      .catch((error) => {
        console.error('Validation failed:', error);
      });
  };

  useEffect(() => {
    if (searchValue === '') {
      setFilteredData(tableData); // If search value is empty, revert to original data
    } else {
      const filtered = tableData.filter((item) => {
        return (
          item.company.company
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.company.id.toString().includes(searchValue.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  }, [searchValue, tableData]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBlock: '2rem',
        }}
      >
        <div style={{ width: '12rem' }}>
          <Input
            variant="outlined"
            placeholder="Search Company"
            onChange={handleSearch}
          />
        </div>
        <MDButton variant="contained" color="primary" onClick={handleAdd}>
          Add Company
        </MDButton>
      </div>

      <Card>
        <MDBox>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            progressPending={pending}
            progressComponent={<CircularProgress />}
            responsive
            highlightOnHover
            expandOnRowClicked
          
          />
        </MDBox>
      </Card>

      {/* ////////////////////// Edit Modal ////////////////////////*/}
      <Modal
        title={`Edit Options for ${selectedCompany.company?.company?.toUpperCase()}`}
        open={editModal}
        onCancel={() => setEditModal(false)}
        onOk={handleEditSubmit}
        okText="Save"
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            emailimportoption: selectedCompany?.emailimportoption?.map(
              (item) => item.values,
            ),
          }}
        >
          <Form.Item
            label="Company Name"
            name={['company', 'company']}
            rules={[
              { required: true, message: 'Please enter the company name' },
            ]}
          >
            <Input disabled style={{ color: 'black' }} />
          </Form.Item>

          <Form.Item
            label="Company ID"
            name={['company', 'id']}
            rules={[{ required: true, message: 'Please enter the company ID' }]}
          >
            <Input style={{ color: 'black' }} disabled />
          </Form.Item>

          <Form.Item
            label="Email Import Options"
            name="emailimportoption"
            rules={[
              { required: true, message: 'Please select email import options' },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select options"
              placement="topLeft"
            >
              <Select.Option key={1} value={3}>
                3 Months
              </Select.Option>
              <Select.Option key={2} value={6}>
                6 Months
              </Select.Option>
              <Select.Option key={3} value={12}>
                1 Year
              </Select.Option>
              <Select.Option key={4} value={100}>
                All time
              </Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* ////////////////////// Add Modal ////////////////////////*/}
      <Modal
        title="Add Company"
        open={addModal}
        onCancel={() => setAddModal(false)}
        onOk={handleSave}
        okText="Add"
      >
        <Form layout="vertical" form={form2}>
          <Form.Item
            label="Select Company"
            name={['company', 'id']}
            rules={[{ required: true, message: 'Please select a company' }]}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input?.toLowerCase())
              }
              placeholder="Select company"
            >
              {companyList.map((company) => (
                <Select.Option key={company.company_name} value={company.id}>
                  {company.company_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Email Import Options"
            name="emailimportoption"
            rules={[
              { required: true, message: 'Please select email import options' },
            ]}
          >
            <Select mode="multiple" placeholder="Select options" placement='topLeft'>
              <Select.Option key={1} value={3}>
                3 Months
              </Select.Option>
              <Select.Option key={2} value={6}>
                6 Months
              </Select.Option>
              <Select.Option key={3} value={12}>
                1 Year
              </Select.Option>
              <Select.Option key={4} value={100}>
                All time
              </Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </DashboardLayout>
  );
};

export default ImportOption;
