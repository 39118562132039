import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Switch,
  Select,
  theme,
  DatePicker,
  notification,
  Collapse,
  Flex,
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import MultiSelectComponent from './temp';
import MultipleSelect, { StylesConfig } from 'react-select';
import { SaveOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { postAPICall } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import { COUNTRY } from 'util/constant';
import { COMPANY_TYPE } from 'util/constant';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import { openNotificationWithIcon } from 'shared';
import AsyncSelect from 'react-select/async';
import dayjs from 'dayjs';

const { Item } = Form;
const Option = Select.Option;

function AddCompanyForm({ visible, onCancel, isView, editData }) {
  const [formData, setFormData] = useState(null);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [userCount, setUserCount] = useState(null);
  const [subscriptionPrice, setSubscriptionPrice] = useState(null);
  const [status, setStatus] = useState(true);
  const [form] = Form.useForm();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [countryForCode, setCountryForCode] = useState({});

  const [VM_ACCESS, setVMAccess] = useState()
  const [MAIL_ACCESS, setMailAccess] = useState()
  const [initialCount, setInitialCount] = useState('500')
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const data = await COUNTRY();
        setCountryList(data);
      } catch (error) {
        console.error('Failed to fetch countries:', error);
      }
    };

    fetchCountries();
  }, []);


  const formItemLayout = {
    labelCol: { span: 24 }, // Change the span value as needed
    wrapperCol: { span: 24 }, // Change the span value as needed
    padding: 24,
  };

  const countryOptions = countryList.map((e, i) => {
    return (
      <Select.Option key={e.id + i} value={e.id.toString()}>
        {e.name}
      </Select.Option>
    );
  });

  const getSelectedCountry = (value) => {
    const country = countryList.find((e) => e.id == value);
    setCountryForCode(country);
    return country ? country.country_code : '';
  };

  const countryCodeOptions = countryList.map((e, i) => {
    return (
      <Option key={e.id + i} value={e.id.toString()}>
        {e.country_code}
      </Option>
    );
  });

  const currencyOptions = countryList.reduce((acc, curr) => {
    if (!acc.some((option) => option.value === curr.currency)) {
      acc.push({
        value: curr.currency,
        label: curr.currency,
      });
    }
    return acc;
  }, []);

 
  const subscriberPlanOptions = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/modules-details`,
        requestOptions,
      );
      const respdata = await resp.json();
      if (respdata) {
        const data = respdata.map((e, i) => {
          return { value: e.product_name, label: e.product_name };
        });
        return data;
      } else {
        openNotificationWithIcon('error', 'Something went worng.');
      }
    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  };

  const companyOptions = COMPANY_TYPE.map((e, i) => {
    return { value: e.name, label: e.name };
  });

  const onChangeSubscriptionPlan = async (plan) => {
    setSelectedPlan(plan.value);
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/plan-details`,
        requestOptions,
      );
      const respdata = await resp.json();
      setModuleOptions(null);
      if (respdata) {
        const data = respdata.map((e, i) => {
          return { value: e.id, label: e.plan_name };
        });
        setModuleOptions(data);
        setUserCount(400);
        setSubscriptionPrice(500);
      } else {
        openNotificationWithIcon('error', 'Something went worng.');
      }
    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  };

  const { token } = theme.useToken();
  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  };

  useEffect(() => {
    const getCompanyData = async () => {
      if (editData?.id) {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };
        const resp = await fetch(
          `${URL_WITH_VERSION}accounts/company-data/${editData.id}`,
          requestOptions,
        );
        const data = await resp.json();
        if (data) {
          setVMAccess(data.company_details[0].VM_ACCESS)
          setMailAccess(data.company_details[0].MAIL_ACCESS)
          if (data['onbording_date'])
            data['onbording_date'] = dayjs(data['onbording_date']);

          if (data?.company_details[0]['onbording_date']) {
            data['company_details'][0]['onbording_date'] = dayjs(
              data['company_details'][0]['onbording_date'],
            );
          }

          if (data?.subscriber_details[0]) {
            data['subscriber_details'][0]['expiry_date'] = data[
              'subscriber_details'
            ][0]['expiry_date']
              ? moment(data['subscriber_details'][0]['expiry_date'])
              : null;
            data['subscriber_details'][0]['status'] = data[
              'subscriber_details'
            ][0]['status']
              ? true
              : false;

            const planData = await subscriberPlanOptions();

            const plan = planData.filter(
              (e) => e.value === data['subscriber_details'][0]['plan_id'],
            );

            data['subscriber_details'][0]['plan_id'] = [plan[0]];
          }

          form.setFieldsValue(data);
          setFormData(data);
          setStatus(data.company_details[0].status === 0 ? false : true);
        } else {
          openNotificationWithIcon('error', 'Something went worng.');
        }
      }
    };
    getCompanyData();
  }, [editData?.id]);

  const handleSave = () => {
    Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure, you want to save it?',
      onOk: () => saveFormData(),
    });
  };

  const saveFormData = () => {
    let url = `${URL_WITH_VERSION}accounts/company-details`;

    let _method = 'post';
    if (editData?.id) {
      _method = 'put';
      url = `${URL_WITH_VERSION}accounts/company-data/${editData.id}`;
    }
    try {
      form
        .validateFields()
        .then((values) => {
          if (values['subscriber_details']) {
            values['subscriber_details'][0]['status'] = values[
              'subscriber_details'
            ][0]['status']
              ? 1
              : 0;
            values['subscriber_details'][0]['plan_id'] =
              values['subscriber_details'][0]['plan_id']['value'];
          }

          values['company_details'][0]['country_code'] =
            countryForCode?.country_code;

          values['company_details'][0]['status'] = status === true ? 1 : 0;
          postAPICall(url, values, _method, (data) => {
            if (data) {
              openNotificationWithIcon(
                'success',
                'Company Details Are saved Successfully',
              );
              onCancel();
              form.resetFields();
            } else {
              openNotificationWithIcon('error', data.msg);
            }
          });
        })
        .catch((errorInfo) => {
          openNotificationWithIcon('error', 'Plese Fill All Mandatory Fields');
        });
    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  };

  const handleDate = (date) => {
    if (date) {
      form.setFieldsValue({
        'company_details[0].onbording_date': date,
      });
    } else {
      form.setFieldsValue({
        'company_details[0].onbording_date': null,
      });
    }
  };

  const filterOption = (input, option) => {
    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const handleInitialCount = (e) => {
    const value = e.target.value;
    setInitialCount(value);
  };

  const initialValues = {
    company_details: [
      {
        initial_email_count: 500,
      },
    ],
  };

  const CompanyDetailForm = () => {
    return (
      <Form form={form} initialValues={initialValues} {...formItemLayout} style={formStyle}>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Company Name"
              name={['company_details', 0, 'company_name']}
              rules={[
                { required: true, message: 'Please enter the company name' },
              ]}
            >
              <Input
                disabled={isView === true ? true : false}
                style={{ width: '100%', color: 'black' }}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Initial Mail Count"
              name={['company_details', 0, 'initial_email_count']}
              rules={[
                { required: true, message: 'Please enter the Email PRoceing Count' },
              ]}
            >
              <Input
                // value={initialCount}
                // onChange={handleInitialCount}
                disabled={isView === true ? true : false}
                style={{ width: '100%', color: 'black' }}
              />
            {<span style={{color: 'red', fontSize: '10px'}}>Default count for mail processing is 500.</span>}
            </Form.Item>
          </Col> */}


<Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Initial Processing Email Count"
              name={['company_details', 0, 'initial_email_count']}
              rules={[
                { required: true, message: 'Please enter the mail count' },
              ]}
            >
              <Input
                type='number'
                disabled={isView === true ? true : false}
                style={{ width: '100%', color: 'black' }}
              />
            </Form.Item>
          </Col>



          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="First Name"
              name={['company_details', 0, 'first_name']}
              rules={[
                { required: true, message: 'Please enter the First name' },
              ]}
            >
              <Input
                disabled={isView === true ? true : false}
                style={{ width: '100%', color: 'black' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Last Name"
              name={['company_details', 0, 'last_name']}
              rules={[
                { required: true, message: 'Please enter the Last name' },
              ]}
            >
              <Input
                disabled={isView === true ? true : false}
                style={{ width: '100%', color: 'black' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Email"
              name={['company_details', 0, 'email']}
              rules={[
                { required: true, message: 'Please enter the email' },
                { type: 'email', message: 'Invalid email format' },
              ]}
            >
              <Input
                autocomplete="off"
                disabled={isView === true ? true : false}
                style={{ width: '100%', color: 'black' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Password"
              name={['company_details', 0, 'password']}
              rules={[{ required: true, message: 'Please enter the Password' }]}
            >
              {/* <Input type="password" autoComplete="new-password" style={{ width: "100%", color: 'black' }} /> */}
              <Input.Password
                autoComplete="new-password"
                style={{ width: '100%', color: 'black' }}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Phone Number"
              name={['company_details', 0, 'phone_number']}
              rules={[
                { required: true, message: 'Please enter the phone number' },
              ]}
            >
              <Input maxLength={10} type="number" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Company Domain"
              name={['company_details', 0, 'company_domain']}
              rules={[
                { required: true, message: 'Please enter the Company Domain' },
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Onboarding Date"
              name={['company_details', 0, 'onbording_date']}
              rules={[
                {
                  required: true,
                  message: 'Please enter the company Onboarding Date',
                },
              ]}
            >
              <DatePicker
                onChange={handleDate}
                format={'YYYY-MM-DD'}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Country"
              name={['company_details', 0, 'country']}
              rules={[{ required: true, message: 'Please enter the Country' }]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                onChange={getSelectedCountry}
                style={{ width: '100%' }}
              >
                {countryOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Country Code"
              name={['company_details', 0, 'country_code']}
            >
              <Select
                showSearch
                disabled
                defaultValue={countryForCode.country_code || ''}
                style={{ width: '100%' }}
              >
                {countryForCode?.country_code ? (
                  <Option
                    key={countryForCode.id}
                    value={
                      countryForCode?.country_code
                        ? countryForCode.country_code.toString()
                        : undefined
                    }
                  >
                    {countryForCode.country_code}
                  </Option>
                ) : (
                  countryCodeOptions
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Currency"
              name={['company_details', 0, 'currency']}
              rules={[{ required: true, message: 'Please enter the currency' }]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                style={{ width: '100%' }}
              >
                {currencyOptions.map((option, index) => (
                  <Option key={index} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Company Type"
              name={['company_details', 0, 'company_type']}
              rules={[
                { required: true, message: 'Please enter the company type' },
              ]}
            >
              <MultipleSelect
                style={{ width: '100%' }}
                closeMenuOnSelect={false}
                value={[companyOptions[0]]}
                isMulti
                options={companyOptions}
                // styles={colourStyles}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Fax Number"
              name={['company_details', 0, 'fax_number']}
              rules={[
                { required: true, message: 'Please enter the fax number' },
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Flex>
              <Form.Item
                label="Mail Access"
                name={['company_details', 0, 'MAIL_ACCESS']}
              >
                <Switch
                  checked={formData?.MAIL_ACCESS || MAIL_ACCESS}
                  onChange={(checked) => setMailAccess(checked)}
                />
              </Form.Item>

              <Form.Item
                label="VM Access"
                name={['company_details', 0, 'VM_ACCESS']}
              >
                <Switch
                  checked={formData?.VM_ACCESS || VM_ACCESS}
                  onChange={(checked) => setVMAccess(checked)}
                />
              </Form.Item>
            </Flex>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="Company Address"
              name={['company_details', 0, 'company_address']}
              rules={[
                { required: true, message: 'Please enter the company address' },
              ]}
            >
              <TextArea style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Status"
              name={['company_details', 0, 'status']}
            >
              <Switch
                checked={status}
                onChange={(checked) => setStatus(checked)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Modal
      title={
        <div style={{ textAlign: 'center' }}>
          {editData?.id
            ? `${isView === true ? 'View' : 'Edit'} Company`
            : 'Add Company'}
        </div>
      }
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={
        isView !== true
          ? [
              <Button
                key="save"
                type="primary"
                icon={<SaveOutlined />}
                onClick={handleSave}
              >
                Save
              </Button>,
            ]
          : ''
      }
    >
      <fieldset disabled={isView} style={{ border: 'none', padding: '0 20px' }}>
        {editData ? (
          <Collapse
            items={[
              {
                key: '1',
                label: 'Company Detail',
                children: <CompanyDetailForm />,
              },
              // {
              //   key: "2",
              //   label: "Database Detail",
              //   children: <DbDetailForm />,
              // },
              // {
              //   key: "3",
              //   label: "Subscription Detail",
              //   children: <SubsCriptionDetailForm />,
              // },
            ]}
            defaultActiveKey={['1']}
          />
        ) : (
          <CompanyDetailForm />
        )}
      </fieldset>
    </Modal>
  );
}

AddCompanyForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  editMode: PropTypes.func.isRequired,
  isView: PropTypes.bool.isRequired,
};

export default AddCompanyForm;
