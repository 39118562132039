import { Col, Form, Input, Modal, Row, Select, Button, theme } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { COUNTRY } from 'util/constant';
import { Spin } from 'antd';
import {
  openNotificationWithIcon,
  postAPICall,
  URL_WITH_VERSION,
} from 'shared';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Autocomplete, IconButton, TextField } from '@mui/material';
// import "../authentication/sign-in/signin.css"
import './requestDemo.css';

const AddRequestDemoForm = (props) => {
  const [formData, setFormData] = useState({
    plan_name: '',
    min_users: null,
    required_no_users: null,
    contact_name: '',
    no_of_emails: null,
    contact_number: '',
    email: '',
    company_name: '',
    country_name: '',
    country_code: '',
    product_type: '',
  });
  const [countryForCode, setCountryForCode] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [productTypeSelected, setProductTypeSelected] = useState(false);
  const [producttype, setProductType] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [spin, setSpin] = useState(false);
  const [company, setCompany] = useState(null);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const data = await COUNTRY();
        setCountryList(data);
      } catch (error) {
        console.error('Failed to fetch countries:', error);
      }
    };

    fetchCountries();
  }, []);

  const { token } = theme.useToken();
  const Option = Select.Option;

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  };
  const [form] = Form.useForm();
  const [PlanOptions, setPlanOptions] = useState([
    { value: 'Pending', label: 'Pending' },
    { value: 'Processing', label: 'Processing' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Active', label: 'Active' },
  ]);
  const [moduleOptions, setModuleOptions] = useState([
    { value: 'Oceann VM', label: 'Oceann VM' },
    { value: 'Oceann Mail', label: 'Oceann Mail' },
  ]);

  const countryOptions = countryList.map((e, i) => (
    <Select.Option key={e.id + i} value={e.id.toString()}>
      {e.name}
    </Select.Option>
  ));

  useEffect(() => {
    getCompanyList();
  }, []);

  const countryCodeOptions = countryList.map((e, i) => {
    return (
      <Option key={e.id + i} value={e.id.toString()}>
        {e.country_code}
      </Option>
    );
  });

  const filterOption = (input, option) => {
    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const handleCountryChange = (value) => {
    const country = countryList.find((e) => e.id.toString() === value);
    const countryCode = country ? country.country_code : '';
    const countryName = country ? country.name : '';

    setCountryForCode(countryCode); // Update state for country code

    form.setFieldsValue({
      country_code: countryCode, // Update the form field
      country_name: countryName, // Update the form field with country name
    });
  };

  const handleSave = async () => {
    setSpin(true);

    try {
      const request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      };

      let url = `${URL_WITH_VERSION}accounts/client-request`;
      const resp = await fetch(url, request);
      const response = await resp.json();
      if (response?.status == true) {
        toast.success('Request added');
        props.close();
        props.getTableData();
        setSpin(false);
      } else {
        console.error('some error occured');
        setSpin(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormChange = (changedValues, allValues) => {
    setFormData(allValues);
  };

  const getCompanyList = async () => {
    const companyResp = await fetch(
      `${URL_WITH_VERSION}accounts/comp_request_list`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const companylist = await companyResp.json();
    setCompanyList(companylist.data);
  };

  const handleCompanyChange = async (event, value) => {
    // debugger;
    try {
      setCompany(value?.company_name);
      const payload = {
        productType: producttype,
        company_name: value?.company_name,
      };

      // console.log('payload', payload);
      const request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      };

      let url = `${URL_WITH_VERSION}accounts/request-prod-check`;
      const resp = await fetch(url, request);
      const response = await resp.json();
      // console.log('response', response);
      const responseData = response?.data;
      if (response?.status == true) {
        if (response?.msg) {
          openNotificationWithIcon('info', response?.msg);
          form.resetFields();
          setProductTypeSelected(false);
        } else {
          form.setFieldsValue({
            plan_name: producttype,
            company_name: value?.company_name,
            email: responseData?.email,
            contact_number: responseData?.contact_number,
            country_name: responseData?.country_name,
            country_code: responseData?.country_code,
            min_users: responseData?.min_users,
            required_no_users: responseData?.required_no_users,
            no_of_emails:
              producttype == 'Oceann VM' ? 0 : responseData?.no_of_emails,
            contact_name: responseData?.contact_name,
          });

          setFormData({
            ...formData,
            plan_name: producttype,
            company_name: value?.company_name,
            email: responseData?.email,
            contact_number: responseData?.contact_number,
            country_name: responseData?.country_name,
            country_code: responseData?.country_code,
            min_users: responseData?.min_users,
            required_no_users: responseData?.required_no_users,
            no_of_emails:
              producttype == 'Oceann VM' ? 0 : responseData?.no_of_emails,
            contact_name: responseData?.contact_name,
          });
          // console.log('formdata', formData);
        }
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleProductTypeChange = async (event, value) => {
    setProductTypeSelected(!!value); // Set to true if a product type is selected
    if (value) {
      setProductType(value?.value);
    }
    if (value?.value == 'Oceann VM') {
      // alert("hi")
      form.setFieldsValue({
        no_of_emails: 0,
      });
      setFormData({
        ...formData,
        no_of_emails: 0,
      });
    }

    try {
      if (company) {
        const payload = {
          productType: value?.value,
          company_name: company,
        };

        // console.log('payload', payload);
        const request = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        };

        let url = `${URL_WITH_VERSION}accounts/request-prod-check`;
        const resp = await fetch(url, request);
        const response = await resp.json();
        // console.log('response', response);
        const responseData = response?.data;
        if (response?.status == true) {
          if (response?.msg) {
            form.resetFields(['plan_name'])
            openNotificationWithIcon("info",response?.msg)
          }
          else{
            console.log("error")
          }
          

        }
        console.log('responseData', responseData);
      }else{
        console.log("some error occured")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };

  const handleBlur = () => {
    if (inputValue) {
      handleCompanyChange(null, { company_name: inputValue });
    }
  };

  const uniqueCompanyList = Array.from(
    new Set(companyList.map((a) => a.company_name)),
  ).map((name) => {
    return companyList.find((a) => a.company_name === name);
  });

  const onFinish = () => {
    handleSave();
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        style={formStyle}
        {...formItemLayout}
        onValuesChange={handleFormChange}
      >
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Full Name"
              name="contact_name"
              rules={[
                { required: true, message: 'Please input your full name!' },
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Module"
              name="plan_name"
              rules={[{ required: true, message: 'Please select a module!' }]}
            >
              <Select
                options={moduleOptions}
                onChange={handleProductTypeChange}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item label="Company Name" name="company_name">
              <div>
                <Autocomplete
                  options={uniqueCompanyList}
                  getOptionLabel={(option) => option.company_name}
                  freeSolo
                  disableClearable
                  disabled={!productTypeSelected}
                  onChange={handleCompanyChange}
                  onInputChange={handleInputChange}
                  className="search-input autoComp"
                  renderInput={(params) => (
                    <TextField
                      // onChange={handleInputChange}
                      {...params}
                      label={false}
                      fullWidth
                      onBlur={handleBlur}
                      sx={{
                        height: '1.8rem',
                      }}
                    />
                  )}
                  style={{ width: '100%' }}
                />
              </div>
              {!productTypeSelected && (
                <div style={{ fontSize: '10px', color: 'red' }}>
                  * Select module first
                </div>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Phone Number"
              name="contact_number"
              rules={[
                { required: true, message: 'Please input your phone number!' },
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Country Name"
              name="country_name"
              rules={[
                { required: true, message: 'Please add your country name' },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                onChange={handleCountryChange}
                style={{ width: '100%' }}
              >
                {countryOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Country Code"
              name="country_code"
              rules={[
                { required: true, message: 'Please provide your country code' },
              ]}
            >
              <Select
                showSearch
                disabled
                value={formData.country_code}
                style={{ width: '100%' }}
              >
                {countryForCode?.country_code ? (
                  <Option
                    key={countryForCode.id}
                    value={countryForCode.country_code}
                  >
                    {countryForCode.country_code}
                  </Option>
                ) : (
                  countryCodeOptions
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Minimum Users"
              name="min_users"
              rules={[
                {
                  required: true,
                  message: 'Please input the minimum number of users!',
                },
              ]}
            >
              <Input type="number" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          {/** 
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Plan Status"
              name="plan_status"
              rules={[
                { required: true, message: 'Please input the plan status!' },
              ]}
            >
              <Select options={PlanOptions} />
            </Form.Item>
          </Col>
            */}

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Required Number of Users"
              name="required_no_users"
              rules={[
                {
                  required: true,
                  message: 'Please input the required number of users!',
                },
              ]}
            >
              <Input type="number" style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Number of Emails"
              name="no_of_emails"
              rules={[
                {
                  required: true,
                  message: 'Please input the number of emails!',
                },
              ]}
            >
              <Input
                type="number"
                style={{ width: '100%' }}
                disabled={producttype == 'Oceann VM' ? true : false}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item label="Message" name="message">
              <TextArea rows={5} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                // onClick={onFinish}
                type="primary"
                htmlType="submit"
                icon={spin ? <LoadingOutlined /> : <SaveOutlined />}
              >
                {spin ? 'Saving...' : 'Save'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

AddRequestDemoForm.propTypes = {
  close: PropTypes.func.isRequired,
  getTableData: PropTypes.func.isRequired,
};

export default AddRequestDemoForm;
