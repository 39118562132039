export const COUNTRY = async() => {
  try {
    const url = `${process.env.REACT_APP_URL}accounts/country-port-data`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData = await response.json();
    return responseData.data || [];
  } catch (e) {
    console.error(e);
    return [];
  }
}


export const COMPANY_TYPE = [
  {
    id: 1,
    name: 'Port Agent',
  },
  {
    id: 2,
    name: 'Service Provider',
  },
  {
    id: 1,
    name: 'Charterer',
  },
  {
    id: 1,
    name: 'All Broker',
  },
  {
    id: 1,
    name: 'Receiver',
  },
  {
    id: 1,
    name: 'Bunker Supplier',
  },
  {
    id: 1,
    name: 'My Company',
  },
  {
    id: 1,
    name: 'Own Company Branch',
  },
  {
    id: 1,
    name: 'Shipper ( Supplier )',
  },
  {
    id: 1,
    name: 'Ship Owner',
  },
  {
    id: 1,
    name: 'Ship Management',
  },
  {
    id: 1,
    name: 'Misc. Company',
  },
];
