import { Box, Grid, InputLabel, TextField } from '@mui/material';
import { Button, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const VesselForm = (props) => {
  const [state, setState] = useState({
    vessel_name: props.formData.vessel_name || '',
    vessel_code: props.formData.vessel_code || '',
    summer_dwt: props.formData.summer_dwt,
    imo_no: props.formData.imo_no || '',
    vessel_type: props.formData.vessel_type || '',
    vessel_sub_type: props.formData.vessel_sub_type || '',
    no_of_cranes: props.formData.no_of_cranes || '',
    id: props.formData.id,
    seaspdconsp_tableperday:[],
    portconsp_tableperday:[]
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      vessel_name: props.formData.vessel_name || '',
      vessel_code: props.formData.vessel_code || '',
      summer_dwt: props.formData.summer_dwt,
      imo_no: props.formData.imo_no || '',
      vessel_type: props.formData.vessel_type || '',
      vessel_sub_type: props.formData.vessel_sub_type || '',
      no_of_cranes: props.formData.no_of_cranes || '',
      id: props.formData.id,
      seaspdconsp_tableperday:[],
      portconsp_tableperday:[]
      
    }));
  }, [props.formData]);

  const handleChange = (field) => (e) => {
    console.log('field', field);
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleUpdateForm = async () => {
    try {
      const url = `${process.env.REACT_APP_URL}accounts/update-vessel`;
      const request = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      };

      const resp = await fetch(url, request);
      const response = await resp.json();

      console.log('response', response);
      if(response.status==1){
        props?.getTableData()
        props?.onclose()
      }else{
        console.log("some error occured")
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Grid
        container
        justifyContent={'space-between'}
        style={{ margin: '20px 0 20px 0' }}
      >
        <Box width={'49%'}>
          <InputLabel>
            Vessel Name
            <Typography style={{ color: 'red', display: 'inline' }}>
              *
            </Typography>
          </InputLabel>

          <TextField
            value={state?.vessel_name}
            name="vessel_name"
            // variant="outlined"
            size="small"
            fullWidth
            onChange={handleChange('vessel_name')}
          />
        </Box>

        <Box width={'49%'}>
          <InputLabel htmlFor="my-email">
            Vessel Code
            <Typography style={{ color: 'red', display: 'inline' }}>
              *
            </Typography>
          </InputLabel>

          <TextField
            size="small"
            name="vessel_code"
            fullWidth
            value={state?.vessel_code}
            onChange={handleChange('vessel_code')}
          />
        </Box>
      </Grid>

      <Grid
        container
        justifyContent={'space-between'}
        style={{ margin: '20px 0 20px 0' }}
      >
        <Box width={'49%'}>
          <InputLabel htmlFor="my-input">IMO no.</InputLabel>

          <TextField
            name="imo_no"
            value={state?.imo_no}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleChange('imo_no')}
          />
        </Box>

        <Box width={'49%'}>
          <InputLabel htmlFor="my-input">Vessel Type</InputLabel>

          <TextField
            name="vessel_type"
            value={state?.vessel_type}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleChange('vessel_type')}
          />
        </Box>
      </Grid>

      <Grid
        container
        justifyContent={'space-between'}
        style={{ margin: '20px 0 20px 0' }}
      >
        <Box width={'49%'}>
          <InputLabel htmlFor="my-input">Vessel Subtype</InputLabel>

          <TextField
            name="vessel_sub_type"
            value={state?.vessel_sub_type}
            type="email"
            size="small"
            fullWidth
            onChange={handleChange('vessel_sub_type')}
          />
        </Box>

        <Box width={'49%'}>
          <InputLabel htmlFor="my-input">Summer dwt</InputLabel>

          <div>
            <TextField
              fullWidth
              // defaultCountry={'us'}
              name="summer_dwt"
              value={state?.summer_dwt}
              variant="outlined"
              size="small"
              className="contact-phone-input"
              onChange={handleChange('summer_dwt')}
            />
          </div>
        </Box>
      </Grid>
      <Grid
        container
        justifyContent={'space-between'}
        style={{ margin: '20px 0 20px 0' }}
      >
        <Box width={'49%'}>
          <InputLabel htmlFor="my-input">No. of cranes</InputLabel>

          <TextField
            name="no_of_cranes"
            value={state?.no_of_cranes}
            type="email"
            size="small"
            fullWidth
            onChange={handleChange('no_of_cranes')}
          />
        </Box>
      </Grid>
      <Grid container justifyContent={'flex-end'} style={{ marginTop: '20px' }}>
        <Button onClick={handleUpdateForm} variant="contained" color="primary">
          Update
        </Button>
      </Grid>
    </div>
  );
};

VesselForm.propTypes = {
  formData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vessel_name: PropTypes.string,
    vessel_code: PropTypes.string,
    summer_dwt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imo_no: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vessel_type: PropTypes.string,
    vessel_sub_type: PropTypes.string,
    no_of_cranes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  getTableData: PropTypes.func.isRequired,
  onclose:PropTypes.func.isRequired
};

VesselForm.defaultProps = {
  formData: {
    id: '',
    vessel_name: '',
    vessel_code: '',
    summer_dwt: '',
    imo_no: '',
    vessel_type: '',
    vessel_sub_type: '',
    no_of_cranes: '',
  },
  getTableData: () => {}, // Default function
  onclose:()=>{}
};

export default VesselForm;
