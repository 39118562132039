import { useEffect, useState } from 'react';

// react-router-dom components
import { Link, useNavigate } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// // Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';

import Cookies from 'universal-cookie';
import { URL_WITH_VERSION } from 'shared';
import { postAPICall } from 'shared';

const cookies = new Cookies();

import React from "react";

import { Button, Checkbox, Form, Grid, Input, theme, Typography } from "antd";

import { EyeFilled, EyeInvisibleFilled, LockOutlined, MailOutlined } from "@ant-design/icons";
import { openNotificationWithIcon } from 'shared';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

export default function Basic() {
  const { token } = useToken();
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {
    const rememberMe = localStorage.getItem('rememberMe') === 'true';
    const email = rememberMe ? localStorage.getItem('email') : '';
    const password = rememberMe ? localStorage.getItem('password') : '';

    setRememberMe(rememberMe);
    setEmail(email);
    setPassword(password);
  }, []);

  const handleSignIn = async () => {
    try {
      let postData = { username: email, password };
      let _method = 'POST';
      
      const response = await fetch(`${URL_WITH_VERSION}accounts/admin-login`, {
        method: _method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });
  
      const data = await response.json();
      
      if (response.ok && data) {
        console.log(data);
        localStorage.setItem('token', data.token);
        let decodedToken = data.token.split('.')[1];
        decodedToken = atob(decodedToken);
        
        let userDetails = JSON.parse(decodedToken);
        localStorage.setItem('user', decodedToken);

        if (rememberMe) {
          localStorage.setItem('rememberMe', true);
          localStorage.setItem('email', email);
          localStorage.setItem('password', password);
        } else {
          localStorage.removeItem('rememberMe');
          localStorage.removeItem('email');
          localStorage.removeItem('password');
        }

        openNotificationWithIcon("success", 'Login Successful');
        window.location = '/dashboard';
      } else {
        openNotificationWithIcon("info", 'Invalid Credentials');
      }
    } catch (err) {
      console.error(err);
      openNotificationWithIcon("error", 'An error occurred during login.');
    }
  };
  

  const isValidEmail = (email) => {
    // Email validation using a regular expression
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px"
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%"
    },
    forgotPassword: {
      float: "right"
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      borderRadius: '10px',
      zIndex: '999',
      position:'relative'
    },
    text: {
      color: token.colorTextSecondary
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <section style={styles.section}>
        <div className='loginContainer' style={styles.container}>
          <div style={styles.header}>
            <Title style={styles.title}>Sign in</Title>
            <div style={{fontSize: '14px', lineHeight: '1.5'}}>
              Welcome back to Oceann Zero - Admin Dashboard, Please enter your details below to
              sign in.
            </div>
          </div>
          <Form
            name="normal_login"
            initialValues={{}}
            onFinish={onFinish}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
              style={{color: "black"}}
            >
              <label style={{marginBottom: '5px', fontWeight: '700', fontSize: '12px'}} htmlFor="email">Email</label>
              <Input
                type="email"
                id='email'
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!isValidEmail(email)} // Check if email is valid
                helperText={
                  !isValidEmail(email) && 'Please enter a valid email'
                }
                style={{color: 'black'}}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
              style={{color: "black", position: 'relative'}}
            >
              <label style={{marginBottom: '5px', fontWeight: '700', fontSize: '12px'}} htmlFor="password">Password</label>
              <Input
                id='password'
                type={showPassword ? 'input' : 'password'}
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className='invisibleEye' onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <EyeFilled /> : <EyeInvisibleFilled />}
              </div>
              
            </Form.Item>
            <Form.Item>
              {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}
              <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
               <Typography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </Typography>
            </MDBox>
              {/* <a style={styles.forgotPassword} href="">
                Forgot password?
              </a> */}
            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
            <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSignIn}
              >
                sign in
              </MDButton>
              {/* <div style={styles.footer}>
                <Text style={styles.text}>Do not have an account?</Text>{" "}
                <Link href="">Sign up now</Link>
              </div> */}
            </Form.Item>
          </Form>
        </div>
      </section>
    </BasicLayout>
  );
}