// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

import DataTable from 'react-data-table-component';

// Data
import { EditOutlined } from '@ant-design/icons';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Tooltip, Modal } from 'antd';
import MDInput from 'components/MDInput';
import RequestDemoForm from 'layouts/requestDemoForm';
import { useEffect, useState } from 'react';
import { openNotificationWithIcon, URL_WITH_VERSION } from 'shared';
import AddRequestDemoForm from 'layouts/requestDemoForm/addRequestDemoForm';
import MDButton from "components/MDButton";

function RequestDemoList() {
  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: undefined,
    isView: false,
  });
  const [isAddCompany, setIsAddComapny] = useState(false);
  const [filtered_items, setFiltereditems] = useState({});
  const [filterText, setfilterText] = useState('');
  const [pending, setPending] = useState(true);
  const [openAddForm, setOpenAddForm] = useState(false);

  const filterdata =
    state?.rows?.filter((item) =>
      JSON.stringify(item)
        .toLocaleLowerCase()
        .includes(filterText.toLowerCase()),
    ) || [];

  const columns = [
    {
      name: <strong>Full Name</strong>,
      selector: (row) => (
        <span style={{ color: 'orange', fontWeight: '600' }}>
          {row?.contact_name}
        </span>
      ),
      sortable: true,
    },
    {
      name: <strong>Company Name</strong>,
      selector: (row) => (
        <span style={{ color: 'navy', fontWeight: '600' }}>
          {row?.company_name || 'N/A'}
        </span>
      ),
      sortable: true,
      width: '150px',
      center: true,
    },
    {
      name: 'Email',
      selector: (row) => (
        <Tooltip title={row?.email}>
          <span>{row?.email}</span>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: 'Phone No.',
      selector: (row) => row?.contact_number,
      sortable: true,
    },
    {
      name: 'Product Name',
      selector: (row) => (
        <span style={{ color: 'navy', fontWeight: '600' }}>
          {row?.plan_name}
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Minimum Users',
      selector: (row) => row?.min_users,
      sortable: true,
      center: true,
    },
    {
      name: 'Required Users',
      selector: (row) => row?.required_no_users,
      sortable: true,
      center: true,
    },
    {
      name: 'No. of Mails.',
      selector: (row) => row?.no_of_emails || 'N/A',
      sortable: true,
      center: true,
    },
    {
      name: 'Country.',
      selector: (row) => row?.country_name || 'N/A',
      sortable: true,
      center: true,
    },
    {
      name: 'Country code',
      selector: (row) => row?.country_code || 'N/A',
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: (row) => row?.plan_status,
      sortable: true,
      width: '120px',
      center: true,
      cell: (row) => (
        <span className={'status_tags' + ' ' + row?.plan_status + 'tags'}>
          {row?.plan_status}
        </span>
      ),
    },
    {
      name: 'Actions',
      center: true,
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <EditOutlined onClick={() => handleEdit(row)} />
        </MDTypography>
      ),
      right: true,
      width: '80px',
    },
  ];
  useEffect(() => {
    getTableData();
  }, [isAddCompany]);

  const getTableData = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/get-client-requests`,
        requestOptions,
      );
      const respdata = await resp.json();
      if (respdata) {
        setState((prevState) => ({ ...prevState, rows: respdata.data }));
        setPending(false);
      } else {
        openNotificationWithIcon('error', 'Something went worng.');
      }
    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  };

  const handleEdit = (row) => {
    setState((prevState) => ({ ...prevState, editData: row }));
    setIsAddComapny(true);
  };

  const handleView = (row) => {
    setState((prevState) => ({ ...prevState, editData: row, isView: true }));
    setIsAddComapny(true);
  };

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      editData: undefined,
      isView: false,
    }));
    setIsAddComapny(false);
  };

  const { editData } = state;

  const close = () => {
    setOpenAddForm(false);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    minWidth: '200px',
                    maxWidth: '600px',
                  }}
                  onChange={(e) => setfilterText(e.target.value)}
                />
                <MDButton
                  onClick={() => setOpenAddForm(true)}
                  variant="contained"
                  color="primary"
                >
                  Add Request
                </MDButton>
              </div>
              <br />
              <br />
              <Card>
                <MDBox>
                  <DataTable
                    columns={columns}
                    data={filterdata}
                    pagination
                    progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      {isAddCompany === true ? (
        <RequestDemoForm
          visible={true}
          onCancel={onCancel}
          onSave={(value) => {
            console.log(value);
          }}
          editData={editData}
          isView={state?.isView}
        />
      ) : undefined}

      {openAddForm ? (
        <Modal title="Add Request Demo" footer={null} open={openAddForm} onCancel={close}>
          <AddRequestDemoForm getTableData={getTableData} close={close} />
        </Modal>
      ) : null}
    </>
  );
}

export default RequestDemoList;
