import { DeleteFilled, EditFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { CircularProgress } from '@mui/material';
import { Input, Modal, Popconfirm, Tooltip } from 'antd';
import { Card } from 'antd';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { openNotificationWithIcon } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import EditCargo from './EditCargo';
import { postAPICall } from 'shared';
import InfiniteScroll from 'react-infinite-scroll-component';

const CargoList = () => {
  const [tableData, setTableData] = useState([]);
  const [pending, setPending] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [editData, setEditData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const columns = [
    {
      name: <strong>Action</strong>,
      width: '80px',
      cell: (row) => (
        <>
          <Tooltip title="Edit">
            <span
              style={{ cursor: 'pointer' }}
              onClick={handleEditFunc(row)}
              className="ellipsis-text"
            >
              <EditFilled />
            </span>
          </Tooltip>
          <Tooltip title="Delete" placement="right">
            <Popconfirm
              title="Delete this Cargo"
              description="Are you sure to delete this row?"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => deleteFunction(row['_id'])}
            >
              <DeleteFilled
                style={{ cursor: 'pointer', paddingLeft: '10px' }}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
    {
      name: <strong>Received Date</strong>,
      key: 'New_Date',
      cell: (row) => (
        <Tooltip title={row.New_Date + " " + row.Time}>
          <span className="ellipsis-text">{row.New_Date + " " + row.Time}</span>
        </Tooltip>
      ),
      width: '170px',
    },
    {
      name: <strong>Cargo</strong>,
      key: 'cargo',
      cell: (row) => (
        <Tooltip
          title={
            Array.isArray(row.cargo) && row.cargo.length > 0
              ? row.cargo.join(', ')
              : row.cargo || "N/A"
          }
        >
          <span className="ellipsis-text" style={{color: 'green', fontWeight: '700'}}>
            {Array.isArray(row.cargo) && row.cargo.length > 0
              ? row.cargo.join(', ')
              : row.cargo || "N/A"}
          </span>
        </Tooltip>
      ),
      width: '120px',
    },
    {
      name: <strong>Cargo QTY. [MT]</strong>,
      key: 'cargo_size',
      width: '150px',
      cell: (row) =>
        Array.isArray(row.cargo_size) && row.cargo_size.length > 0
          ? row.cargo_size.map((e, index) => (
              <Tooltip key={index} title={e}>
                <span className="ellipsis-text" style={{color: 'deeppink', fontWeight: '700'}}>{e}</span>
              </Tooltip>
            ))
          : <span className="ellipsis-text" style={{color: 'deeppink', fontWeight: '700'}}>{row.cargo_size || "N/A"}</span> || "N/A",
    },
    {
      name: <strong>Company Name</strong>,
      key: 'company',
      width: '150px',
      cell: (row) => row.company || 'N/A',
    },
    {
      name: <strong>Receiver</strong>,
      key: 'New_From',
      width: '120px',
      cell: (row) =>
        Array.isArray(row.New_From) && row.New_From.length > 0
          ? row.New_From.map((e, index) => (
              <Tooltip key={index} title={e}>
                <span className="ellipsis-text">{e}</span>
              </Tooltip>
            ))
          : 'N/A',
    },
    {
      name: <strong>Sender</strong>,
      key: 'client',
      cell: (row) => (
        <Tooltip title={row.Client}>
          <span className="ellipsis-text">{row.Client ?? 'N/A'}</span>
        </Tooltip>
      ),
      width: '120px',
    },
    {
      name: <strong>Load Port</strong>,
      key: 'load_port',
      cell: (row) => (
        <Tooltip title={row.load_port.port}>
          <span className="ellipsis-text">{row.load_port.port ?? 'N/A'}</span>
        </Tooltip>
      ),
    },
    {
      name: <strong>Laycan</strong>,
      key: 'laycan',
      cell: (row) => (
        <Tooltip title={row.laycan}>
          <span className="ellipsis-text">{row.laycan ?? 'N/A'}</span>
        </Tooltip>
      ),
    },
    {
      name: <strong>Discharge Port</strong>,
      key: 'discharge_port',
      cell: (row) => (
        <Tooltip title={row.discharge_port.port}>
          <span className="ellipsis-text">{row.discharge_port.port ?? 'N/A'}</span>
        </Tooltip>
      ),
    },
  ];

  const handleEditFunc = (row) => () => {
    setEditData(row);
    setIsEditable(true);
  };

  const deleteFunction = async (id) => {
    setPending(true);
    try {
      const url = `${URL_WITH_VERSION}accounts/delete-cargo-tonnage-mails/cargo/${id}`;
      const method = 'POST';

      await postAPICall(url, {}, method, (data) => {
        openNotificationWithIcon('success', data?.msg || 'Data deleted successfully.');
        resetTableData();
      });
    } catch (err) {
      openNotificationWithIcon('error', err.message || 'An error occurred while deleting the data.');
    }
    setPending(false);
  };

  useEffect(() => {
    loadMoreData(20, currentPage);
  }, []);

  const loadMoreData = async (size, page) => {
    setPending(true);
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        body: JSON.stringify({ Mailtype: 'cargo' }),
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/cargo-tonnage-mails?pageSize=${size}&page=${page}`,
        requestOptions,
      );
      const respdata = await resp.json();

      if (respdata?.emails.length > 0) {
        setTableData(prev => [...prev, ...respdata.emails]);
        setCurrentPage(prev => prev + 1);
      } else {
        setHasMore(false);
      }
      setPending(false);
    } catch (err) {
      openNotificationWithIcon('error', err.message || 'An error occurred while fetching table data.');
      setPending(false);
    }
  };

  const resetTableData = () => {
    setTableData([]);
    setCurrentPage(1);
    setHasMore(true);
    loadMoreData(20, 1);
  };

  const filteredData = tableData.filter((row) => {
    const values = Object.values(row).join(' ');
    return values.toLowerCase().includes(searchValue.toLowerCase());
  });

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <div style={{ display: 'flex', alignItems: 'center', maxWidth: '250px' }}>
            <MDInput
              type="text"
              style={{ minWidth: '200px' }}
              name="search"
              id="search"
              value={searchValue}
              onChange={handleSearch}
              label="Search here"
            />
          </div>
        </MDBox>

        <Card>
          <MDBox>
            <InfiniteScroll
              dataLength={filteredData.length}
              next={() => loadMoreData(20, currentPage + 1)}
              hasMore={hasMore}
              loader={<p style={{ textAlign: 'center', marginBlock: '16px' }}><b>Loading...</b></p>}
              endMessage={<p style={{ textAlign: 'center' }}>No more data to load.</p>}
            >
              <DataTable
                columns={columns}
                data={filteredData}
                pagination={false}
                progressPending={pending && currentPage === 1}
                progressComponent={<CircularProgress />}
                responsive
                highlightOnHover
                expandOnRowClicked
              />
            </InfiniteScroll>
          </MDBox>
        </Card>
      </DashboardLayout>
      <Modal
        open={isEditable}
        onCancel={() => setIsEditable(false)}
        title="Edit Cargo Details"
        width={800}
        className="EditTonnageModal"
        footer={null}
      >
        <EditCargo
          getTableData={resetTableData}
          setIsEditable={setIsEditable}
          editData={editData}
        />
      </Modal>
    </>
  );
};

export default CargoList;
