// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import DataTable from "react-data-table-component";

// Data
import { useEffect, useState } from "react";
import { URL_WITH_VERSION } from "shared";
import {CircularProgress } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import AddPaymentForm from "layouts/addPaymentForm";
import { openNotificationWithIcon } from "shared";
import moment from "moment";
import Badge from "@material-ui/core/Badge";
import MenuListModal from "layouts/MenulistModal";
function MenuList() {
  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: {},
    isView: false,
  });
  const [isAddCompany, setIsAddComapny] = useState(false);
  const [filterText, setfilterText] = useState("");
  const [pending, setPending] = useState(true);
  const [count, setCount] = useState(0);
  const [filterdata,setFilterData] = useState([])

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      editData: undefined,
      isView: false,
    }));
    setIsAddComapny(false);
    setCount(count + 1);
  };

  const columns = [
    {
      name: <strong>Company name</strong>,
      selector: (row) => row?.name,
      sortable: true,
      center: true,
    },
    {
      name: "Parent",
      selector: (row) => row?.parent?.name,
      center: true,
      cell: (row) => {
        if (row.parent == null){
            return "No parent";
        }
        return row.parent.name;
      }
    },
    {
      name: "Creation Time",
      selector: (row) => row?.created_at,
      sortable: true,
      cell: (row) => moment(row?.created_at).format("DD-MM-YYYY"),
      center: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      center: true,
      cell: (row) => {
        let status = row?.status;
        if (status == true) {
          return (
            <span className="status_tags Activetags">Active</span>
          );
        }
        return (
          
          <span className="status_tags Processingtags">Deactivated</span>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <EditOutlined onClick={() => handleEdit(row)} />
        </MDTypography>
      ),
      right: true,
      width: "80px",
      center: true,
    },
  ];
  useEffect(() => {
    getTableData();
  }, [count]);

  const getTableData = async () => {
    try {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/admin_list`,
        requestOptions
      );
      const respdata = await resp.json();
      setState((prevState) => ({ ...prevState, rows: respdata }));
      setFilterData(respdata)
      setPending(false);
    } catch (err) {
      openNotificationWithIcon("error", err);
    }
  };

  // const filterdata =
  //   state?.rows?.filter((item) =>
  //     JSON.stringify(item)
  //       .toLocaleLowerCase()
  //       .includes(filterText.toLowerCase())
  //   ) || [];

  const handleEdit = (row) => {
    setState((prevState) => ({ ...prevState, editData: row, isView: true }));
    setIsAddComapny(true);
  };

  const { editData } = state;

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDInput
                  label="Search here"
                  value={filterText}
                  style={{
                    flex: 1,
                    marginRight: "20px",
                    minWidth: "200px",
                    maxWidth: "600px",
                  }}
                  onChange={(e) => {
                    setfilterText(e.target.value)
                    setFilterData(state?.rows?.filter((item) =>
                      JSON.stringify(item)
                      .toLocaleLowerCase()
                      .includes(filterText.toLowerCase())
                    ))
                  }}
                />
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddComapny(true)}
                >
                  Add Menu
                </MDButton>
              </div>
              <br />
              <br />
              <Card>
                <MDBox  key={count}>
                  <DataTable
                    columns={columns}
                    data={filterdata}
                    pagination
                    progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      {isAddCompany === true ? (
        <MenuListModal
          visible={true}
          onCancel={onCancel}
          onSave={(value) => {
            console.log(value);
          }}
          editData={editData}
          isView={state?.isView}
        />
      ) : undefined}
    </>
  );
}

export default MenuList;
