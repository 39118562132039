import { SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  theme
} from "antd";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { openNotificationWithIcon, postAPICall, URL_WITH_VERSION } from "shared";

const { Item } = Form;
const Option = Select.Option;

function AdduserModal({ visible, onCancel, editMode, editData, isView }) {
  const formItemLayout = {
    labelCol: { span: 24 }, // Change the span value as needed
    wrapperCol: { span: 24 }, // Change the span value as needed
  };

  const [count, setCount] = useState(0);
  const [roleList, setRoleList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const { token } = theme.useToken();
  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    display: "flex",
    flexDirection: "column",
  };

  useEffect(() => {
    getRoleList();
    getCompanyList();
  }, [count]);

  const [form] = Form.useForm();

  const getRoleList = async () => {
    try {
      const roleListData = await fetch(
        `${URL_WITH_VERSION}accounts/role-details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let roleList = await roleListData.json();
      console.log("roleList-->>>", roleList);
      if (setRoleList) {
        setRoleList(roleList);
      }
    } catch (err) {
      console.log("error while fetching", err);
    }
  };
  const getCompanyList = async () => {
    const companyResp = await fetch(
      `${URL_WITH_VERSION}accounts/company-details`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const companylist = await companyResp.json();
    setCompanyList(companylist);
  };

  const handleSave = () => {
    let _method = "post";
    if (editData?.id) {
      _method = "put";
    }
    form
      .validateFields()
      .then((values) => {
        values["status"] = values["status"] ? 1 : 0;
        values["id"] = editData?.id;
        if (values["company_id"])
          values["company_id"] = values["company_id"]["value"];
        postAPICall(
          `${URL_WITH_VERSION}accounts/add-subscription`,
          values,
          _method,
          (data) => {
            if (data.status === "1") {
              openNotificationWithIcon(
                "success",
                "User Details Are saved Successfully"
              );
              setCount(count + 1);
              onCancel();
            } else {
              openNotificationWithIcon("error", data.msg);
            }
          }
        );
        // form.resetFields();
        // onSave(values);
      })
      .catch((errorInfo) => {
        openNotificationWithIcon("error", "Plese Fill All Mandatory Fields");
      });
  };

  return (
    <Modal
      title={<div style={{ textAlign: "center" }}>Add Subscription</div>}
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={[
        <Button
          key="save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <fieldset style={{ border: "none" }}>
        <input type="hidden" value={count} />
        <Form form={form} {...formItemLayout} style={formStyle}>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Company Name"
                name="company_name"
                rules={[
                  { required: true, message: "Please enter the Company Name" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Company"
                  options={companyList.map((cname) => ({
                    value: cname?.id,
                    label: cname?.company_name,
                  }))}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Company Email"
                name="company_mail"
              >
                <Input />
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Product"
                name="product"
                rules={[
                  { required: true, message: "Please enter the Comapany mail" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Product"
                  options={[
                    {
                      value: "oceannVM",
                      label: "OceannVM",
                    },
                    {
                      value: "oceannMail",
                      label: "OceannMail",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Expiry Date"
                name="expiry_date"
                rules={[
                  { required: true, message: "Please enter the Expiry Date" },
                ]}
              >
                <DatePicker format={"DD-MM-YYYY"} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Subscription Type"
                name="subscription_type"
                rules={[
                  { required: true, message: "Please enter the Comapany mail" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Subscription Type"
                  options={[
                    {
                      value: "yearly",
                      label: "Yearly",
                    },
                    {
                      value: "monthly",
                      label: "Monthly",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Number of Users"
                name="number_of_users"
                rules={[
                  { required: true, message: "Please enter the User Type" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </fieldset>
    </Modal>
  );
}

AdduserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  editMode: PropTypes.func.isRequired,
  isView: PropTypes.bool.isRequired,
};

export default AdduserModal;
