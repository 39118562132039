import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { Card, Input, Table } from 'antd';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useState } from 'react';

const OpenAiHitList = () => {
  const [omTable, setOmTable] = useState([]);
  const [vmTable, setVmTable] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [inputValuesvm, setInputValuesvm] = useState({});
  const [vmUserCount, setVmUserCount] = useState(null);
  const[companyNames,setCompanyNames]=useState([]);
  const [omUserCount, setOmUserCount] = useState(null);
  useEffect(() => {
    handleOmTable();
    getCompanyNames();
    // handleVmTable();
  }, []);

  const handleOmTable = async () => {
    try {
      const url = `${process.env.REACT_APP_URL}marine/get-user-hits`;
      const requestOptions = {
        method: 'GET',
      };

      const resp = await fetch(url, requestOptions);

      const responseData = await resp.json();

      if (responseData.status == true) {
        setOmTable(responseData.users);
        setOmUserCount(responseData?.no_of_users);
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyNames=async()=>{
    const url=`${process.env.REACT_APP_URL}marine/companies-list`
    const requestOptions = {
      method: 'GET',
    };

    const resp = await fetch(url, requestOptions);
    const responseData = await resp.json();
    // console.log("responseeeee",responseData);
    if(responseData?.status==true){
      // const companies= responseData?.companies?.map((ele)=>ele.company)
      setCompanyNames(responseData?.companies)
    }else{
      console.log("some error occured")
    }

  }

  // const handleVmTable = async () => {
  //   try {
  //     const url = `${process.env.REACT_APP_URL_BETA}api/v1/oceann_ai/user_hits`;

  //     const requestOptions = {
  //       method: 'GET',
  //     };

  //     const resp = await fetch(url, requestOptions);

  //       const responseData = await resp.json();

  //       if (responseData.Status == true) {
  //       setVmTable(responseData?.users);
  //       setVmUserCount(responseData?.no_of_records);
  //     } else {
  //       console.log('error');
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const omColumns = [
    {
      title: 'User Name',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Total Hits',
      dataIndex: 'total_hits',
      key: 'total_hits',
    },
    {
      title: 'Used Hits',
      dataIndex: 'user_hits',
      key: 'user_hits',
    },
    {
      title: 'Increase hits',
      dataIndex: 'increase_hits',
      key: 'increase_hits',
      render: (text, record) => (
        <Input
          value={inputValues[record.user]}
          onChange={(e) => handleInputChange(e, record)}
          placeholder="0"
          onKeyDown={(e) => handleKeyDown(e, record)}
        />
      ),
    },
  ];

  // const vmColumns = [
  //   {
  //     title: 'User Name',
  //     dataIndex: 'user_name',
  //     key: 'user_name',
  //   },
  //   {
  //     title: 'Total Hits',
  //     dataIndex: 'total_hits',
  //     key: 'total_hits',
  //   },
  //   {
  //     title: 'Used Hits',
  //     dataIndex: 'user_hits',
  //     key: 'user_hits',
  //   },
  //   {
  //     title: 'Increase hits',
  //     dataIndex: 'increase_hits',
  //     key: 'increase_hits',
  //     render: (text, record) => (
  //       <Input
  //         value={inputValuesvm[record.user_name]}
  //         onChange={(e) => handleInputChangeVm(e, record)}
  //         placeholder="0"
  //         onKeyDown={(e) => handleKeyDownVm(e, record)}
  //       />
  //     ),
  //   },
  // ];
  const handleInputChange = (e, record) => {
    const { value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [record.user]: value,
    }));
  };

  // const handleInputChangeVm = (e, record) => {
  //   const { value } = e.target;
  //   setInputValuesvm((prevValues) => ({
  //     ...prevValues,
  //     [record.user_name]: value,
  //   }));
  // };

  const handleKeyDown = async (e, record) => {
    try {
      if (e.key === 'Enter') {
        const inputValue = parseInt(e.target.value, 10);

        if (isNaN(inputValue)) {
          console.log('Invalid input value:', e.target.value);
          return;
        }

        setInputValues('');

        const url = `${process.env.REACT_APP_URL}marine/increase-user-hits`;
        const payload = {
          email: record.user,
          user_hits: inputValue,
        };
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        };

        const resp = await fetch(url, requestOptions);
        const response = await resp.json();

        if (response.status === true) {
          const updatedOmTable = omTable.map((item) => {
            if (item.user === record.user) {
              const currentHits = parseInt(item.total_hits, 10);

              if (isNaN(currentHits)) {
                console.log('Invalid total_hits value:', item.total_hits);
                return item;
              }

              const newTotalHits = currentHits + inputValue;
              return {
                ...item,
                total_hits: newTotalHits,
              };
            }
            return item;
          });

          setOmTable(updatedOmTable);
        } else {
          console.log('Some error occurred');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleKeyDownVm = async (e, record) => {

  //   try {
  //     if (e.key === 'Enter') {
  //       const increaseValue = parseInt(e.target.value, 10);
  //       if (isNaN(increaseValue)) {
  //         console.log('Invalid input value');
  //         return;
  //       }

  //       setInputValuesvm((prev) => ({
  //         ...prev,
  //         [record.user_name]: '',
  //       }));

  //       const url = `${process.env.REACT_APP_URL_BETA}api/v1/oceann_ai/user_hits`;
  //       const payload = {
  //         user_mail: record.user_name,
  //         increase_hit: increaseValue,
  //       };
  //       const requestOptions = {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(payload),
  //       };
  //       const resp = await fetch(url, requestOptions);
  //       const response = await resp.json();

  //       if (response) {
  //         const updatedVmTable = vmTable.map((item) => {
  //           if (item.user_name === record.user_name) {
  //             // Ensure correct field reference
  //             const newTotalHits =
  //               parseInt(item.total_hits, 10) + increaseValue; // Convert total_hits to number before adding
  //             return {
  //               ...item,
  //               total_hits: newTotalHits,
  //             };
  //           }
  //           return item;
  //         });

  //         setVmTable(updatedVmTable);
  //       } else {
  //         console.log('some error occurred');
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleCompanyChange= async(event,value)=>{
    console.log("value",value)
    const url=`${process.env.REACT_APP_URL}marine/get-user-hits`
    const payload = {
      company_id:value?.id
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    const resp = await fetch(url, requestOptions);
    const response = await resp?.json();
    // console.log("response",response)
    if(response?.status==true){
      setOmUserCount(response?.no_of_users)
      setOmTable(response?.users)
    }else{
      console.log("some error occured")
    }

  }

  // console.log("company",companyNames)
  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        <Card>
          <MDBox>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>Open AI Hit Count</h3>
              <h5>Total Users: {omUserCount}</h5>
            </div>
            <div
              style={{
                marginTop: '16px',
                marginBottom:"16px",
                display: 'flex',
                alignItems: 'center',
               
              }}
            >
              <label
                htmlFor="company-name-autocomplete"
                style={{ marginRight: '8px' }}
              >
                Company Name:
              </label>
              <Autocomplete
                id="company-name-autocomplete"
                fullWidth
                options={companyNames} 
                getOptionLabel={(option) => option?.company}
                style={{ width:"20rem"}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Company Name"
                    variant="outlined"
                    // placeholder='Select company here'
                  />
                )}
                onChange={handleCompanyChange}
              />
            </div>
            <Table
              pagination={false}
              columns={omColumns}
              dataSource={omTable}
              fullWidth
            />
          </MDBox>
        </Card>
        {/**
          <Card style={{ marginTop: '4rem' }}>
          <MDBox>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>VM Hit Count</h3>
              <h5>Total Users: {vmUserCount}</h5>
            </div>
            <Table
              pagination={false}
              columns={vmColumns}
              dataSource={vmTable}
            />
          </MDBox>
        </Card>
           */}
      </DashboardLayout>
    </div>
  );
};

export default OpenAiHitList;
