// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

import DataTable from 'react-data-table-component';

// Data
import { useEffect, useState } from 'react';
import { URL_WITH_VERSION } from 'shared';
import {CircularProgress} from '@mui/material';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import AddModuleForm from 'layouts/addModuleForm';
import { openNotificationWithIcon } from 'shared';

function Modules() {
  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: undefined,
    isView: false,
  });
  const [isAddCompany, setIsAddComapny] = useState(false);
  const [filterText, setfilterText] = useState('');
  const [pending, setPending] = useState(true);
  const [count, setcount] = useState(0);
  const [filterdata, setFilterdata] = useState([])

  // const filterdata = state?.rows?.filter((item) =>
  //   JSON.stringify(item).toLocaleLowerCase().includes(filterText.toLowerCase()),
  // )||[];

  const onCancel = () => {
    setState((prevState) => ({ ...prevState, editData: undefined, isView:false }));
    setIsAddComapny(false)
    setcount(count + 1)
  }



  const columns = [
    {
      name: 'Product Name',
      selector: (row) => row?.product_name,
      sortable: true,
      center: true,
    },
    {
      name: 'Product Price ($)',
      selector: (row) => row?.product_price_per_user,
      sortable: true,
      center: true,
    },
    {
      name: 'ATC Adjustment ($)',
      selector: (row) => row?.ATC_adjustment,
      sortable: true,
      center: true,
    },
    {
      name: 'Discount ($)',
      selector: (row) => row?.discount,
      sortable: true,
      center: true,
    },
    {
      name: 'Training charges ($)',
      selector: (row) => row?.training_charges,
      sortable: true,
      center: true,
    },
    {
      name: 'No. of users (Min.)',
      selector: (row) => row?.minimum_users,
      sortable: true,
      center: true,
    },
    
    {
      name: 'Actions',
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <EditOutlined onClick={() => handleEdit(row)}/>
        </MDTypography>
      ),
      right: true,
      width: '80px',
      center: true,
    },
  ];
  useEffect(() => {
    getTableData();
  }, [count]);

  const getTableData = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const resp  = await fetch(`${URL_WITH_VERSION}accounts/modules-details`,requestOptions)
      const respdata = await resp.json();
      setFilterdata(respdata)
      if(respdata){
        setState((prevState) => ({ ...prevState, rows: respdata }));
        setPending(false)
      }else {
        openNotificationWithIcon("error", "Something went worng.");
      }
    } catch (err) {
      openNotificationWithIcon("error", err);
    }
  };

  const handleEdit = (row) => {
    setState((prevState) => ({ ...prevState, editData: row, isView:true }));
    setIsAddComapny(true);
  };

  const { editData } = state;

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    minWidth: '200px',
                    maxWidth: '600px',
                  }}
                  onChange={(e) => {
                    setfilterText(e.target.value)
                    setFilterdata(state?.rows?.filter((item) =>
                      JSON.stringify(item)
                     .toLocaleLowerCase()
                     .includes(filterText.toLowerCase())
                    ))  
                  }}
                />
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddComapny(true)}
                >
                  Add Package
                </MDButton>
              </div>
              <br />
              <br />
              <Card>
                <MDBox key={count}>
                  <DataTable columns={columns} data={filterdata} pagination progressPending={pending} progressComponent={<CircularProgress />}/>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      {isAddCompany === true ? (
        <AddModuleForm
          visible={true}
          onCancel={onCancel}
          onSave={(value) => {
            console.log(value);
          }}
          editData={editData}
          isView={state?.isView}
        />
      ) : undefined}
    </>
  );
}

export default Modules;
