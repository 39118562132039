import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Row, Col, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { toast } from 'react-toastify';
import { URL_WITH_VERSION } from 'shared';
import { postAPICall } from 'shared';
import { openNotificationWithIcon } from 'shared';

const EditTonnage = ({ editData, getTableData, setIsEditable }) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [pending, setPending] = useState(false);

  const fieldConfig = [
    {
      name: 'vessel_name',
      label: 'Vessel Name',
      placeholder: 'Vessel Name',
      editable: true,
    },
    {
      name: 'vessel_type',
      label: 'Vessel Type',
      placeholder: 'Vessel Type',
      editable: true,
    },
    {
      name: 'sub_vessel_type',
      label: 'Vessel Sub type',
      placeholder: 'Vessel Sub type',
      editable: true,
    },
    { name: 'dwt', 
      label: 'DWT', 
      placeholder: 'DWT',
      editable: true },
    {
      name: 'open_area',
      label: 'Open area',
      placeholder: 'Open area',
      editable: true,
    },
    {
      name: 'open_date',
      label: 'Open date',
      placeholder: 'Open date',
      editable: true,
    },
    { name: 'Client', 
      label: 'Client', 
      placeholder: 'Client', 
      editable: false 
    },
    { name: 'cranes', 
      label: 'Cranes', 
      placeholder: 'Cranes', 
      editable: false 
    },
    {
      name: 'grt_and_nrt',
      label: 'GRT & NRT',
      placeholder: 'GRT & NRT',
      editable: false,
    },
    {
      name: 'grain_and_bale',
      label: 'Grain & bale',
      placeholder: 'Grain & bale',
      editable: false,
    },
    {
      name: 'hold_and_hatches',
      label: 'Hold & hatches',
      placeholder: 'Hold & hatches',
      editable: false,
    },
    { name: 'flag', 
      label: 'Flag', 
      placeholder: 'Flag', 
      editable: false 
    },
    { name: 'draft', 
      label: 'Draft', 
      placeholder: 'Draft', 
      editable: false 
    },
    {
      name: 'new_open_port',
      label: 'New open port',
      placeholder: 'New open port',
      editable: false,
    },
    {
      name: 'loa_and_beam',
      label: 'LOA & Beam',
      placeholder: 'LOA & Beam',
      editable: false,
    },

  ];

  const getValueOrDefault = (value) =>
    value !== null && value !== undefined ? value : 'N/A';

  useEffect(() => {
    const values = {};
    fieldConfig.forEach(({ name }) => {
      values[name] = getValueOrDefault(editData[name]);
    });
    form.setFieldsValue(values);
    setInitialValues(values);
  }, [editData, form]);

  const convertToAppropriateType = (key, value) => {
    const numericFields = ['dwt'];
    return numericFields.includes(key) ? Number(value) : value;
  };

  const isFieldChanged = (values, initialValues) => {
    return fieldConfig.some(({ name, editable }) => {
      if (!editable) return false;
      const currentValue = convertToAppropriateType(name, values[name]);
      const initialValue = convertToAppropriateType(name, initialValues[name]);
      return currentValue !== initialValue;
    });
  };

  const onFinish = async(values) => {
    setPending(true)
    values['_id'] = editData['_id'];
    values['dwt'] = Number(values['dwt']);

    if (isFieldChanged(values, initialValues)) {
       const url = `${URL_WITH_VERSION}accounts/update-cargo-tonnage-mails/tonnage`
       const method = 'POST'
      
      try {
        postAPICall( url, values, method, (data) => {
          if (data) {
            console.log(data);
            openNotificationWithIcon(
              "success",
              "Tonnage updated successfully"
            );
            getTableData()
            setIsEditable(false);
            setPending(false)
          } else {
            setPending(false)
            openNotificationWithIcon("error", "Something went wrong!! Please try again.");
          }
        })

      } catch (err) {
        setPending(false)
        console.log("Error:", err);
        toast.error('Error while updating tonnage');
        return;
      }


    } else {
      setPending(false)
      openNotificationWithIcon("info", "You haven't changed anything. Please change some values to update.")
    }
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={editData}
      >
        <Row gutter={8}>
          {fieldConfig?.map(({ name, label, editable, isTextArea, placeholder }) => (
            <Col sm={8} key={name}>
              <Form.Item name={name} label={label}>
                {isTextArea ? (
                  <TextArea
                    style={{ minHeight: '100px' }}
                    placeholder={placeholder}
                    disabled={!editable}
                  />
                ) : (
                  <Input placeholder={placeholder} disabled={!editable} />
                )}
              </Form.Item>
            </Col>
          ))}
          <Col sm={24} style={{ textAlign: 'right' }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit <Spin fullscreen spinning={pending} />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

EditTonnage.propTypes = {
  editData: PropTypes.shape({
    _id: PropTypes.string,
    Client: PropTypes.string,
    vessel_name: PropTypes.string,
    vessel_type: PropTypes.string,
    dwt: PropTypes.number,
    draft: PropTypes.string,
    flag: PropTypes.string,
    loa_and_beam: PropTypes.string,
    new_open_port: PropTypes.string,
    open_area: PropTypes.string,
    open_date: PropTypes.string,
    sub_vessel_type: PropTypes.string,
    speed_and_consumption: PropTypes.string,
    cranes: PropTypes.string,
    grt_and_nrt: PropTypes.string,
    grain_and_bale: PropTypes.string,
    hold_and_hatches: PropTypes.string,
    sender_full_details: PropTypes.string,
  }),
  getTableData: PropTypes.func.isRequired,
  setIsEditable: PropTypes.func.isRequired,
};

export default EditTonnage;
