import { SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { openNotificationWithIcon, postAPICall, URL_WITH_VERSION } from 'shared';

const { Item } = Form;
const Option = Select.Option;

function AddSubscriptionForm({
  visible,
  onCancel,
  editMode,
  editData,
  isView,
}) {
  const formItemLayout = {
    labelCol: { span: 24 }, // Change the span value as needed
    wrapperCol: { span: 24 }, // Change the span value as needed
  };

  const [subscriberData, setsubscriberData] = useState([])
  const [count, setCount] = useState(0);
  const [expiryDate, setExpiryDate] = useState(undefined);

  // const loadOptions = async () => {
  //   try {
  //     const requestOptions = {
  //       method: 'GET',
  //       redirect: 'follow',
  //     };

  //     const resp = await fetch(
  //       `${URL_WITH_VERSION}accounts/modules-details`,
  //       requestOptions,
  //     );
  //     const respdata = await resp.json();
  //     if (respdata) {
  //       const data = respdata.map((e, i) => {
  //         return { value: e.module_name, label: e.module_name };
  //       });
  //       return data;
  //     } else {
  //       openNotificationWithIcon('error', 'Something went worng.');
  //     }
  //   } catch (err) {
  //     openNotificationWithIcon('error', err);
  //   }
  // };
  // const companyOptions = async () => {
  //   try {
  //     const requestOptions = {
  //       method: 'GET',
  //       redirect: 'follow',
  //     };

  //     const resp = await fetch(
  //       `${URL_WITH_VERSION}accounts/company-details`,
  //       requestOptions,
  //     );
  //     const respdata = await resp.json();
  //     console.log('company Detail-->',respdata)
  //     if (respdata) {
  //       const data = respdata.map((e, i) => {
  //         return { value: e.id, label: e.company_name };
  //       });
  //       return data;
  //     } else {
  //       openNotificationWithIcon('error', 'Something went worng.');
  //     }
  //   } catch (err) {
  //     openNotificationWithIcon('error', err);
  //   }
  // };

  const { token } = theme.useToken();
  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  };


  useEffect(() => {
    setFormdata();
    // getCompanyData();
  }, [count]);


  const [form] = Form.useForm();
  const setFormdata = ()=>{
    if (editData?.Expire_date) {
      setExpiryDate(dayjs(editData.Expire_date));
    }
    editData.Expire_date = dayjs(editData?.Expire_date)
    editData.companyName = editData?.company?.company_name
    editData.product_name = editData?.product_name
    editData.company_email = editData?.company?.email
    editData.subs_type = editData?.subScription_type
    editData.number_of_users = editData?.number_of_users
    form.setFieldsValue(editData);
  }

  // const getCompanyData = async () => {
  //   if (editData?.id) {
  //     const requestOptions = {
  //       method: 'GET',
  //       redirect: 'follow',
  //     };
  //     const resp = await fetch(
  //       `${URL_WITH_VERSION}accounts/subscriber/${editData.id}`,
  //       requestOptions,
  //     );
  //     const data = await resp.json();
  //     if (data) {
  //       data['expiry_date'] = data['expiry_date']
  //         ? moment(data['expiry_date'])
  //         : moment();
  //       data['status'] = data['status'] ? true : false;
  //       data['plan_id'] = [
  //         {
  //           value: data['plan_id']['id'],
  //           label: data['plan_id']['plan_name'],
  //         },
  //       ];
  //       form.setFieldsValue(data);
  //     } else {
  //       openNotificationWithIcon('error', 'Something went worng.');
  //     }
  //   }
  // };

  const handleSave = () => {
    let _method = 'post';
    if (editData?.id) {
      _method = 'put';
    }
    form
      .validateFields()
      .then((values) => {
        values['status'] = values['status'] ? 1 : 0;
        values['id'] = editData?.id;
        if (values['company_id'])
          values['company_id'] = values['company_id']['value'];
        postAPICall(
          `${URL_WITH_VERSION}accounts/updateSubscriber`,
          values,
          _method,
          (data) => {
            if (data.status === '1') {
              openNotificationWithIcon(
                'success',
                'SubsCription Details Are saved Successfully',
              );
              setCount(count + 1)
              onCancel()
            } else {
              openNotificationWithIcon('error', data.msg);
            }
          },
        );
        // form.resetFields();
        // onSave(values);
      })
      .catch((errorInfo) => {
        openNotificationWithIcon('error', "Plese Fill All Mandatory Fields");
      });
  };

  return (
    <Modal
      title={
        <div style={{ textAlign: 'center' }}>
          {editData?.id ? 'Edit Subscription' : 'Add Subscription'}
        </div>
      }
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={[
        <Button
          key="save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <fieldset style={{ border: 'none' }}>
        <input type='hidden' value={count} />
        <Form form={form} {...formItemLayout} style={formStyle}>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Company Name"
                name="companyName"
                // rules={[
                //   { required: true, message: 'Please enter the company name' },
                // ]}
              >
                <Input disabled style={{color: 'black'}}/>
              </Form.Item> 
            </Col>
            {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Company Email"
                name="company_email"
                // rules={[
                //   { required: true, message: 'Please enter the company email' },
                // ]}
              >
                <Input disabled style={{color: 'black'}}/>
              </Form.Item> 
            </Col> */}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
                label="Product Name"
                name="product_name"
                // rules={[
                //   { required: true, message: 'Please enter the Product name' },
                // ]}
              >
                <Input disabled value={"OceannVM"} style={{color: 'black'}}/>
              </Form.Item> 
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
                label="Subscription Type"
                name="subs_type"
                // rules={[
                //   { required: true, message: 'Please enter the Product name' },
                // ]}
              >
                <Input disabled style={{color: 'black'}}/>
              </Form.Item> 
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Number of Users"
                name="number_of_users"
                rules={[
                  { required: false, message: 'Please enter the description' },
                ]}
              >
                <Input type='number'  style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Expiry Date"
                name={"Expire_date"}
              >
                <DatePicker style={{ width: '100%' }} value={expiryDate} onChange={(date) => setExpiryDate(date)}  />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Status"
                name="status"
                // rules={[{ required: true, message: 'Please enter the status' }]}
              >
                <Switch defaultChecked/>
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </fieldset>
    </Modal>
  );
}

AddSubscriptionForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  editMode: PropTypes.func.isRequired,
  isView: PropTypes.bool.isRequired,
};

export default AddSubscriptionForm;
