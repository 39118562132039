import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Switch,
  Select,
  theme,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { openNotificationWithIcon } from 'shared';
import { URL_WITH_VERSION } from 'shared';

const { Item } = Form;
const Option = Select.Option;

function AddModuleForm({ visible, onCancel, editMode, editData }) {
  const [formData, setFormData] = useState(null);

  const { token } = theme.useToken();

  const formItemLayout = {
    labelCol: { span: 24 }, // Change the span value as needed
    wrapperCol: { span: 24 }, // Change the span value as needed
  };
  
  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  };


  useEffect(() => {
    const getCompanyData = async () => {
      if (editData?.id) {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };

        const resp = await fetch(
          `${URL_WITH_VERSION}accounts/module/${editData.id}`,
          requestOptions,
        );
        const data = await resp.json();
        if (data) {
          setFormData(data);
        } else {
          openNotificationWithIcon('error', 'Something went worng.');
        }
      }
    };
    getCompanyData();
    if(editData?.id){
      setFormdata()
    }
  }, []);

  const [form] = Form.useForm();
  const setFormdata = () => {
    editData.product_name = editData?.product_name;
    editData.password = editData?.password;
    editData.role = editData?.role;
    editData.status = editData?.status;
    editData.company_name = editData?.company_id?.company_name;
    editData.comapny_status = editData?.company_id?.status;
    editData.phone_num = editData?.phone_number
      ? editData?.phone_number
      : "Not Available";
    form.setFieldsValue(editData);
  };

  const handleSave = () => {
    let _method = 'POST';
    let url = `${URL_WITH_VERSION}accounts/modules-details`;
    if (editData?.id) {
      _method = 'PUT';
    }
    
    form
      .validateFields()
      .then(async (values) => {
        values['status'] = values['status'] ? 1 : 0;
        if (editData?.id) values['id'] = editData?.id;
        
        try {
          const response = await fetch(url, {
            method: _method,
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
          });
  
          const data = await response.json();
  
          if (response.ok && data.status === 1) {
            openNotificationWithIcon(
              'success',
              'Module Details Are saved Successfully',
            );
            onCancel();
          } else {
            openNotificationWithIcon('info', 'Products with this Product Name already exists.');
          }
        } catch (error) {
          console.error('Fetch error:', error);
          openNotificationWithIcon('error', 'An error occurred during the request');
        }
      })
      .catch((errorInfo) => {
        openNotificationWithIcon('error', 'Please Fill All Mandatory Fields');
      });
  };
  

  // Set initial form values when in "edit" mode
  if (formData) {
    form.setFieldsValue(formData);
  }

  return (
    <Modal
      title={
        <div style={{ textAlign: 'center' }}>
          {editData?.id ? 'Edit Modules' : 'Add Product'}
        </div>
      }
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={[
        <Button
          key="save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} {...formItemLayout} style={formStyle}>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Product Name"
              name="product_name"
              rules={[{ required: true, message: 'Please enter the Product Name' }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Description"
              name="product_description"
              rules={[
                { required: true, message: 'Please enter the description' },
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Product Price"
              name="product_price_per_user"
              rules={[{ required: true, message: 'Please enter the Product price' }]}
            >
              <Input type='number' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Discount"
              name="discount"
              rules={[{ required: true, message: 'Please enter the Discount' }]}
            >
              <Input type='number' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Training charges"
              name="training_charges"
              rules={[{ required: true, message: 'Please enter the Training charges' }]}
            >
              <Input type='number' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="ATC Adjustment"
              name="ATC_adjustment"
              rules={[{ required: true, message: 'Please enter the ATC Adjustment' }]}
            >
              <Input type='number' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Number of users (Minimum)"
              name="minimum_users"
              rules={[{ required: true, message: 'Please enter the Number of users' }]}
            >
              <Input type='number' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Status"
              name="status"
            >
              <Switch defaultChecked/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

AddModuleForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  editMode: PropTypes.func.isRequired,
};

export default AddModuleForm;
