import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Tooltip } from '@mui/material';
import { Button, Card, Input, Modal, Space, Table, Typography } from 'antd';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import ContactForm from './ContactForm';
import { openNotificationWithIcon } from 'shared';
import DataTable from 'react-data-table-component';

const ContactList = () => {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [allData, setAllData] = useState(null);
  const [contactFormData, setContactFotmData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [id, setid] = useState(null);
  const [data, setData] = useState({});
  useEffect(() => {
    addContactData(currentPage);
    // getAllData()
  }, [currentPage]);
  // const pageSize = 10;

  const addContactData = async (pageNumber) => {
    try {
      const url = `${process.env.REACT_APP_URL}accounts/contact_data?pageSize=${pageSize}&pageNumber=${pageNumber}`;
      const request = {
        method: 'GET',
      };
      const response = await fetch(url, request);
      const responseData = await response.json();
      if (response) {
        const filteredData = responseData?.data.filter(
          (row) =>
            !(
              row.name === '' &&
              row.company === '' &&
              row.phone === '' &&
              row.email === '' &&
              row.website === '' &&
              row.skype === ''
            ),
        );
        setTableData(filteredData);
        setFilteredData(filteredData);
        setTotalRecords(responseData?.records);
        setTotalData(responseData?.total);
        setLoading(false);
      } else {
        console.log('some error occured');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchValue === '') {
      // If search value is empty, revert to original data
      setFilteredData(tableData);
      setTotalRecords(tableData.length);
    } else {
      const filtered = filteredData.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchValue.toLowerCase()),
        ),
      );
      setFilteredData(filtered);
      setTotalRecords(filtered.length);
      setCurrentPage(1); // Reset to first page on search
    }
  }, [searchValue, tableData]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  // const columns = [
  //   {
  //     name: 'Name',
  //     selector: (row) => (
  //       <Tooltip title={row} placement="top">
  //         <span>{row}</span>
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     name: 'Company',

  //     selector: (row) => (
  //       <Tooltip title={row} placement="top">
  //         <span>{row}</span>
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     name: 'Phone Number',

  //     selector: (text, record) => (
  //       <Tooltip
  //         title={record?.item?.phone_numbers?.join(', ')}
  //         placement="top"
  //       >
  //         <span>{record.phone}</span>
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: 'Email Id',
  //     dataIndex: 'email',
  //     key: 'email',
  //     ellipsis: true,
  //     render: (text, record) => (
  //       <Tooltip title={record?.item?.emails?.join(', ')} placement="top">
  //         <span>{text.length > 40 ? `${text.substring(0, 40)}...` : text}</span>
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: 'Website',
  //     dataIndex: 'website',
  //     key: 'website',
  //     ellipsis: true,
  //     render: (text, record) => (
  //       <Tooltip title={record?.item?.websites?.join(', ')} placement="top">
  //         <span>
  //           {text && text.length > 40 ? `${text.substring(0, 40)}...` : text}
  //         </span>
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: 'Skype Id',
  //     dataIndex: 'skype',
  //     key: 'skype',
  //     ellipsis: true,
  //     render: (text, record) => (
  //       <Tooltip title={record?.item?.skype?.join(', ')} placement="top">
  //         <span>
  //           {text && text.length > 40 ? `${text.substring(0, 40)}...` : text}
  //         </span>
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: 'Client',
  //     dataIndex: 'client',
  //     key: 'client',
  //     Width: '10rem',
  //     render: (text) => (
  //       <Tooltip title={text} placement="top">
  //         <span>{text}</span>
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: 'Action',
  //     key: 'action',
  //     render: (text, record) => (
  //       <Space size="middle">
  //         <Tooltip title="Edit" placement="top">
  //           <div
  //             onClick={() => handleEdit(record)}
  //             style={{ cursor: 'pointer' }}
  //           >
  //             <EditOutlined />
  //           </div>
  //         </Tooltip>
  //         <Tooltip title="Delete" placement="top">
  //           <div
  //             onClick={() => handleDelete(record)}
  //             style={{ cursor: 'pointer', color: 'red' }}
  //           >
  //             <DeleteOutlined />
  //           </div>
  //         </Tooltip>
  //       </Space>
  //     ),
  //   },
  // ];

  const columns = [
    {
      name: 'Name',
      selector: (row) => (
        <Tooltip title={row.name} placement="top">
          <span>{row.name ||"N/A"}</span>
        </Tooltip>
      ),
    },
    {
      name: 'Company',
      selector: (row) => (
        <Tooltip title={row.company} placement="top">
          <span>{row.company ||"N/A"}</span>
        </Tooltip>
      ),
    },
    {
      name: 'Phone Number',
      selector: (row) => (
        <Tooltip title={row.item?.phone_numbers?.join(', ')} placement="top">
          <span>{row.phone ||"N/A"}</span>
        </Tooltip>
      ),
    },
    {
      name: 'Email Id',
      selector: (row) => (
        <Tooltip title={row.item.emails?.join(', ')} placement="top">
          <span>
            {row.email.length > 40
              ? `${row.email.substring(0, 40)}...`
              : row.email ||"N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      name: 'Website',
      selector: (row) => (
        <Tooltip title={row.item.websites?.join(', ')} placement="top">
          <span>
            {row.website && row.website.length > 40
              ? `${row.website.substring(0, 40)}...`
              : row.website ||"N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      name: 'Skype Id',
      selector: (row) => (
        <Tooltip title={row?.item?.skype?.join(', ')} placement="top">
          <span>
            {row?.item?.skype && row?.item?.skype?.length > 40
              ? `${row?.skype?.substring(0, 40)}...`
              : row?.skype ||"N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      name: 'Client',
      selector: (row) => (
        <Tooltip title={row.client} placement="top">
          <span>{row.client || "N/A"}</span>
        </Tooltip>
      ),

      style: { width: '10rem' },
    },
    {
      name: 'Action',
      cell: (row) => (
        <Space size="middle">
          <Tooltip title="Edit" placement="top">
            <div onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }}>
              <EditOutlined />
            </div>
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <div
              onClick={() => handleDelete(row)}
              style={{ cursor: 'pointer', color: 'red' }}
            >
              <DeleteOutlined />
            </div>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const handleDelete = (record) => {
    // console.log("record",record)
    setOpenDelete(true);
    setid(record._id);
    setData(record);
  };
  const handleEdit = (record) => {
    setContactFotmData(record);
    setOpenModal(true);
  };

  const handleTableChange = (pagination) => {
    console.log('pagination', pagination);
    setCurrentPage(pagination);
    setPageSize(10);
  };

  const downloadXLSWithHeaders = (headers, data, filename) => {
    try {
      // Create workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet([headers]); // Create worksheet with headers

      // Add data to worksheet

      XLSX.utils.sheet_add_json(worksheet, data, {
        skipHeader: true,
        origin: 'A2', // Start adding data from second row
      });

      // Set column widths
      const wscols = headers.map((header) => ({ wch: header.length }));
      worksheet['!cols'] = wscols;

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Write workbook and create download link
      XLSX.writeFile(workbook, filename || 'download.xlsx');
    } catch (error) {
      console.log(error);
    }
  };

  const headers = [
    'Name',
    'Company',
    'Phone Number',
    'Email Id',
    'Website',
    'Skype Id',
  ];
  const filename = 'ContactList.xlsx';

  const onclose = () => {
    setOpenModal(false);
  };

  const deleteContact = async () => {
    try {
      const { _id, name, company, ...filteredData } = data;
      const transformedData = {
        phone_numbers: filteredData.phone,
        emails: filteredData.email,
        website: filteredData.website,
        skype: filteredData.skype,
      };
      const url = `${process.env.REACT_APP_URL}accounts/delete-vessel-data/${id}`;
      const request = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transformedData),
      };

      const resp = await fetch(url, request);
      const response = await resp.json();
      console.log('response', response);
      setOpenDelete(false);
      if (response.status == true) {
        openNotificationWithIcon('success', 'Contact deleted successfully');
        addContactData(1);
      } else {
        console.log('some error occured');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllData = async () => {
    try {
      const url = `${process.env.REACT_APP_URL}accounts/contact_data?pageSize=${totalData}&pageNumber=${1}`;
      const request = {
        method: 'GET',
      };
      const response = await fetch(url, request);
      const responseData = await response.json();
      if (response) {
        setAllData(responseData?.data);
        downloadXLSWithHeaders(headers, allData, filename);
      } else {
        console.log('some error occured');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Card>
          <MDBox>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>Contact List</h3>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div
                  style={{
                    marginRight: '2rem',
                    fontSize: '20px',
                    marginBottom: '20px',
                    cursor: 'pointer',
                  }}
                >
                  <Tooltip placement="top" title="Sync Contacts">
                    <SyncOutlined />
                  </Tooltip>
                </div>
                <div
                  style={{
                    marginRight: '2rem',
                    fontSize: '20px',
                    marginBottom: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    getAllData();
                  }}
                >
                  <Tooltip placement="top" title="Download CSV">
                    <DownloadOutlined />
                  </Tooltip>
                </div>
                <h5>Records:{totalRecords}</h5>
              </div>
            </div>
            <div style={{ width: '12rem', marginBottom: '2rem' }}>
              <Input
                variant="outlined"
                placeholder="Search contact here"
                onChange={handleSearch}
              />
            </div>
            {/*<Table
              
              pagination={{
                current: currentPage,
                pageSize,
                total: totalData,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50'],
                showQuickJumper: false,
              }}
              columns={columns}
              dataSource={filteredData}
              onChange={handleTableChange}
              loading={loading}
            />*/}
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={pageSize}
              paginationTotalRows={totalData}
              paginationServer
              onChangePage={handleTableChange}
              progressPending={loading}
              paginationRowsPerPageOptions={[10, 20, 50]}
              paginationDefaultPage={currentPage}
            />
          </MDBox>
        </Card>
        {openModal ? (
          <Modal footer={null} open={openModal} onCancel={onclose}>
            <ContactForm
              getTableData={addContactData}
              onclose={onclose}
              formData={contactFormData}
            />
          </Modal>
        ) : null}

        {openDelete ? (
          <Modal
            footer={null}
            open={openDelete}
            onCancel={() => setOpenDelete(false)}
          >
            <Typography>
              Are you sure you want to delete this contact?
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button>Cancel</Button>
              <Button onClick={deleteContact}>Ok</Button>
            </div>
          </Modal>
        ) : null}
      </DashboardLayout>
    </div>
  );
};

export default ContactList;
