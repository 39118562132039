import { useState, useEffect, useMemo } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import Sidenav from 'examples/Sidenav';
import Configurator from 'examples/Configurator';
import SignIn from './layouts/authentication/sign-in';

// Material Dashboard 2 React themes
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';

// Material Dashboard 2 React Dark Mode themes
import themeDark from 'assets/theme-dark';
import themeDarkRTL from 'assets/theme-dark/theme-rtl';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Material Dashboard 2 React routes
import routes from './routes';

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from 'context';

// Images
import brandWhite from 'assets/images/logo-ct.png';
import brandDark from 'assets/images/logo-ct-dark.png';
import { openNotificationWithIcon } from 'shared';
import { CodeSandboxCircleFilled } from '@ant-design/icons';
import '../src/main.css'
window.backendurl = 'https://9122-103-154-247-225.ngrok-free.app';

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const url = window.location.href
  

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      console.log('Token found, setting isLoggedIn to true');
      setIsLoggedIn(true);
    }
  }, []);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === 'rtl' ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === 'dashboard' && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Oceann Zero Dashboard"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === 'vr' && <Configurator />}
        <Routes>
          {localStorage.getItem('token') ? (
            getRoutes(routes)
          ) : (
            <Route path="/authentication/sign-in" element={<SignIn />} />
          )}
          <Route
            path="*"
            element={
              localStorage.getItem('token') ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/authentication/sign-in" />
              )
            }
          />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === 'dashboard' && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Oceann Zero Dashboard"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === 'vr' && <Configurator />}
      <Routes>
        {isLoggedIn ? (
          getRoutes(routes)
        ) : (
          <Route path="/authentication/sign-in" element={<SignIn />} />
        )}
        <Route
          path="*"
          element={
            isLoggedIn ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/authentication/sign-in" />
            )
          }
        />
      </Routes>
    </ThemeProvider>
  );
}

window.getapi_call = async (userInput, timeout = 5000) => {
  // console.log('inside api call');
  try {
    const { url, method, body } = userInput;
    const options = {};
    options.method = method;
    options.headers = {
      'Content-Type': 'application/json',
    };
    options.body = JSON.stringify(body);

    const apiRequest = fetch(url, options);
    const timeoutPromise = new Promise((_, reject) => {
      console.log('inside promise');
      setTimeout(() => {
        reject(
          console.log('time out api'),
          // new Error('Request timed out')
        );
      }, timeout);
    });

    Promise.race([apiRequest, timeoutPromise]).then((data) => {
      console.log(data, 'kkk');
    });

    // if (response instanceof Error) {
    //   // Handle timeout error
    //   openNotificationWithIcon('error', 'Request timed out');
    // } else if (!response.ok) {
    //   // Handle server errors (e.g., non-2xx status codes)
    //   const errorResponse = await response.text();
    //   return { success: false, message: errorResponse };
    // } else {
    //   const data = await response.json(); // Assuming the response is JSON
    //   return { success: true, data };
    // }
  } catch (error) {
    // Handle other exceptions
    console.error(error);
    
    // openNotificationWithIcon('error', 'An error occurred: ' + error.message);
  }
};

// window.getapi_call = async (userInput) => {
//   try {
//     const { url, method, body } = userInput;
//     const options = {};
//     options.method = method;
//     options.headers = {
//       'Content-Type': 'application/json',
//     };
//     options.body = JSON.stringify(body);
//     let response = await fetch(url, options);
//     if (response.status === 200) {
//       const data = await response.json(); // Assuming the response is JSON
//       return { success: true, data };
//     } else {
//       const errorResponse = await response.text(); // Convert the response to a string
//       return { success: false, message: errorResponse };
//     }
//   } catch (error) {
//     openNotificationWithIcon('error', 'Could not complete your request');
//   }
// };
