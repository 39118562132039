import { SaveOutlined , LoadingOutlined} from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, theme } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { COUNTRY } from 'util/constant';

import {
  openNotificationWithIcon,
  postAPICall,
  URL_WITH_VERSION,
} from 'shared';
import { Autocomplete, TextField } from '@mui/material';

const { Option } = Select;

function RequestDemoForm({ visible, onCancel, editMode, editData }) {
  const [formData, setFormData] = useState(null);
  const [countryForCode, setCountryForCode] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [spin, setSpin] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([
    { value: 'Oceann VM', label: 'Oceann VM' },
    { value: 'Oceann Mail', label: 'Oceann Mail' },
  ]);

  
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const data = await COUNTRY();
        setCountryList(data);
      } catch (error) {
        console.error('Failed to fetch countries:', error);
      }
    };

    fetchCountries();
  }, []);

  const [PlanOptions, setPlanOptions] = useState([
    { value: 'Pending', label: 'Pending' },
    { value: 'Processing', label: 'Processing' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Active', label: 'Active' },
  ]);

  const { token } = theme.useToken();

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  };

  useEffect(() => {
    if (editData) {
      setFormData(editData);
    }
  }, [editData]);

  const [form] = Form.useForm();
  useEffect(() => {
    if (countryForCode) {
      form.setFieldsValue({
        country_code: countryForCode.country_code,
        country_name: countryForCode.name,
      });
    }
  }, [countryForCode, form]);

  // useEffect(() => {
  //   if (formData?.company_name) {
  //     const defaultCompany = companyList.find(
  //       (option) => option.company_name === formData.company_name,
  //     );
  //     console.log('Default Company:', defaultCompany?.company_name);
  //     setSelectedCompany(defaultCompany || null);
  //   }
  // }, [formData, companyList]);



  const handleCompanyChange = (event, value) => {
    setSelectedCompany(value);
    form.setFieldsValue({ company_name: value?.company_name });
  };

 

  const handleSave = async (values) => {
    setSpin(true);
    let _method = 'post';
    let url = `${URL_WITH_VERSION}accounts/update-plan-status`;

    try {
      await form.validateFields();

      if (editData?.id) values['id'] = editData?.id;
      console.log('valuessss', values);
      if (
        Array.isArray(values.company_name) &&
        values.company_name.length > 0
      ) {
        values.company_name = values.company_name[0];
      }

      const response = await fetch(url, {
        method: _method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();

      if (response.status === 200) {
        openNotificationWithIcon('success', data?.msg);
        onCancel();
        setSpin(false);
      } else if (response.status === 400) {
        openNotificationWithIcon(
          'info',
          'Email or company name already exists',
        );
        setSpin(false);
      } else {
        openNotificationWithIcon('info', data.msg || 'An error occurred');
        setSpin(false);
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Please fill all mandatory fields');
    }
  };

  // Set initial form values when in "edit" mode
  if (formData) {
    form.setFieldsValue(editData || formData);
  }

  const handleSubmit = (values) => {
    handleSave(values);
  };

  const countryOptions = countryList.map((e, i) => (
    <Select.Option key={e.id + i} value={e.id.toString()}>
      {e.name}
    </Select.Option>
  ));

  const filterOption = (input, option) =>
    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const getSelectedCountry = (value) => {
    const country = countryList.find((e) => e.id.toString() === value);
    setCountryForCode(country || {});
    form.setFieldsValue({
      country_code: country ? country.country_code : '',
      country_name: country ? country.name : '',
    });
  };

  const countryCodeOptions = countryList.map((e) => (
    <Option key={e.id} value={e.country_code}>
      {e.country_code}
    </Option>
  ));

  const getCompanyList = async () => {
    const companyResp = await fetch(
      `${URL_WITH_VERSION}accounts/comp_request_list`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const companylist = await companyResp.json();
    setCompanyList(companylist?.data);
  };

  console.log('formdata', formData);

  // const handleChange = (value) => {

  //   const filteredValue = value.filter(val => val.trim() !== '');
  //   setSelectedCompanies(filteredValue);
  // };

  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>Request Demo</div>}
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={false}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{ company_name: editData?.company_name || '' }}
        style={formStyle}
        {...formItemLayout}
      >
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Full Name"
              name="contact_name"
              rules={[
                { required: true, message: 'Please input your full name!' },
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Company Name"
              name="company_name"
              // rules={[{ required: true, message: 'Please select your Company name!' }]}
            >
            <Select
                style={{ width: '100%' }}
                placeholder="Select a company"
                mode="default"
              >
                {companyList.map((option) => (
                  <Option key={option.id} value={option.company_name}>
                    {option.company_name}
                  </Option>
                ))}
              </Select> 
              

              {/** 
                <Autocomplete
                  options={companyList}
                  getOptionLabel={(option) => option.company_name}
                  freeSolo
                  disableClearable
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                  // value={editData?.company_name}
                  // disabled={!productTypeSelected}
                  // onChange={handleCompanyChange}
                  // onInputChange={handleInputChange}
                  className="search-input autoComp"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={false}
                      fullWidth
                      // onBlur={handleBlur}
                      sx={{
                        height: '1.8rem',
                      }}
                    />
                  )}
                  style={{ width: '100%' }}
                />
                */}
                
                 
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          {/* </Row>
        <Row gutter={24}> */}
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Phone Number"
              name="contact_number"
              rules={[
                { required: true, message: 'Please input your phone number!' },
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Country"
              name="country_name"
              rules={[{ required: true, message: 'Please enter the Country' }]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                onChange={getSelectedCountry}
                style={{ width: '100%' }}
              >
                {countryOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item label="Country Code" name="country_code">
              <Select
                showSearch
                disabled
                value={countryForCode.country_code || ''}
                style={{ width: '100%' }}
              >
                {countryForCode?.country_code ? (
                  <Option
                    key={countryForCode.id}
                    value={countryForCode.country_code}
                  >
                    {countryForCode.country_code}
                  </Option>
                ) : (
                  countryCodeOptions
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Module"
              name="plan_name"
              rules={[{ required: true, message: 'Please select a module!' }]}
            >
              <Select value={editData?.plan_name} options={moduleOptions} />
            </Form.Item>
          </Col>
          {/* </Row>
        <Row gutter={24}> */}

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Minimum Users"
              name="min_users"
              rules={[
                {
                  required: true,
                  message: 'Please input the minimum number of users!',
                },
              ]}
            >
              <Input type="number" style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Plan Status"
              name="plan_status"
              rules={[
                { required: true, message: 'Please input the plan status!' },
              ]}
            >
              <Select value={editData?.plan_status} options={PlanOptions} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Required Number of Users"
              name="required_no_users"
              rules={[
                {
                  required: true,
                  message: 'Please input the required number of users!',
                },
              ]}
            >
              <Input type="number" style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          {/** <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item label="Price of Plan" name="price_of_plan">
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>*/}

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Number of Emails"
              name="no_of_emails"
              rules={[
                {
                  required: true,
                  message: 'Please input the number of emails!',
                },
              ]}
            >
              <Input type="number" style={{ width: '100%' }} disabled={editData?.plan_name=="Oceann VM"?true:false} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item label="Message" name="message">
              <TextArea rows={5} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" htmlType="submit" icon={spin ? <LoadingOutlined/> : <SaveOutlined />}>
              {spin ? 'Saving...' : 'Save'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

RequestDemoForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
};

export default RequestDemoForm;
