// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { getAllRolesService } from "services/all";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import DataTable from "react-data-table-component";

// Data
import { useEffect, useState } from "react";
import { getAPICall } from "shared";
import { URL_WITH_VERSION } from "shared";
import axios from "axios";
import { RoomTwoTone } from "@mui/icons-material";
import { Button, CircularProgress, Icon, Input, SvgIcon } from "@mui/material";
import MDInputRoot from "components/MDInput/MDInputRoot";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AddCompanyForm from "layouts/addCompany";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { postAPICall } from "shared";
import { openNotificationWithIcon } from "shared";
import Edituser from "layouts/editUser/edituser";
import AdduserModal from "layouts/addUser/adduser";
import Badge from "@material-ui/core/Badge";

function Users() {
  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: undefined,
  });
  const [isEditUser, setIsEditUser] = useState(false);
  const [isAddUser, setIsAddUser] = useState(false);
  const [filtered_items, setFiltereditems] = useState({});
  const [filterText, setfilterText] = useState("");
  const [pending, setPending] = useState(true);
  const [count, setCount] = useState(0);

  const filterdata =
    state?.rows?.filter(
      (item) =>
        item.company_id?.company_name
          .toLocaleLowerCase()
          .includes(filterText.toLocaleLowerCase()) ||
        item.role
          ?.toLocaleLowerCase()
          .includes(filterText.toLocaleLowerCase()) ||
        item.email
          ?.toLocaleLowerCase()
          .includes(filterText.toLocaleLowerCase()) ||
        item.company_id.company_name
          ?.toLocaleLowerCase()
          .includes(filterText.toLocaleLowerCase())||
        item.company_id.first_name
          ?.toLocaleLowerCase()
          .includes(filterText.toLocaleLowerCase())
      //   JSON.stringify(item)
      //     .toLocaleLowerCase()
      //     .includes(filterText.toLowerCase())
    ) || [];

  const columns = [
    {
      name: "User Name",
      selector: (row) => row?.first_name,
      sortable: true,
      isView: false,
      center: true,
    },
    {
      name: "User Email",
      selector: (row) => row?.email,
      sortable: true,
      isView: false,
      center: true,
    },
    {
      name: "Password",
      selector: (row) => row?.password,
      sortable: true,
      center: true,
    },
    {
      name: "User Role",
      selector: (row) => row?.role,
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      center: true,
      cell: (row) => {
        let status = row?.status;
        if (status == 1) {
          return (
            <span className="status_tags Approvedtags">Active</span>
          );
        }
        return (
          <span className="status_tags Processingtags">Deactive</span>
        );
      },
    },
    {
      name: "Company",
      selector: (row) => row?.company_id?.company_name,
      sortable: true,
      center: true,
    },
    {
      name: "Company Status",
      selector: (row) => row?.company_id?.status,
      sortable: true,
      center: true,

      cell: (row) => {
        let status = row?.company_id?.status;
        if (status == 1) {
          return (
            <Badge
              color="secondary"
              className="status-badge success"
              badgeContent="Active"
            ></Badge>
          );
        }
        return (
          <Badge
            color="error"
            className="status-badge error"
            badgeContent="Deactive"
          ></Badge>
        );
      },
    },
    {
      name: "Actions",
      center: true,
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <EditOutlined onClick={() => handleEdit(row)} />
        </MDTypography>
      ),
      right: true,
      width: "80px",
    },
  ];

  useEffect(() => {
    getTableData();
  }, [count]);

  // const getTableData = async () => {
  //   try {
  //     const requestOptions = {
  //       method: 'GET',
  //       redirect: 'follow',
  //     };

  //     // const resp = await fetch(
  //     //   `${URL_WITH_VERSION}accounts/role-details`,
  //     //   requestOptions,
  //     // );
  //     const resp = getAllRolesService();
  //     const respdata = await resp.json();
  //     if (respdata) {
  //       setState((prevState) => ({ ...prevState, rows: respdata }));
  //       setPending(false);
  //     } else {
  //       openNotificationWithIcon('error', 'Something went worng.');
  //     }
  //   } catch (err) {
  //     openNotificationWithIcon('error', err);
  //   }
  // };

  const getTableData = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/users`,
        requestOptions
      );
      const respdata = await resp.json();
      setState((prevState) => ({
        ...prevState,
        rows: respdata,
      }));
      if (respdata) {
        setState((prevState) => ({ ...prevState, rows: respdata }));
        setPending(false);
      } else {
        openNotificationWithIcon("error", "Something went wrong.");
      }
    } catch (err) {
      console.error("Error fetching table data:", err); // Log the error
      openNotificationWithIcon("error", "Failed to fetch table data.");
    }
  };

  const handleEdit = (row) => {
    setState((prevState) => ({ ...prevState, editData: row, isView: true }));
    setIsEditUser(true);
  };

  const handleView = (row) => {
    setState((prevState) => ({ ...prevState, editData: row }));
    setIsEditUser(true);
  };

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      editData: undefined,
      isView: false,
    }));
    setIsEditUser(false);
    setIsAddUser(false)
    setCount(count + 1);
  };

  const { editData } = state;

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: "20px",
                    minWidth: "200px",
                    maxWidth: "600px",
                  }}
                  onChange={(e) => setfilterText(e.target.value)}
                />
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddUser(true)}
                >
                  Add Users
                </MDButton>
              </div>
              <br />
              <br />
              <Card>
                <MDBox key={count}>
                  <DataTable
                    columns={columns}
                    data={filterdata}
                    pagination
                    progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      {isEditUser === true ? (
        <Edituser
          visible={true}
          onCancel={onCancel}
          onSave={(value) => {
            console.log(value);
          }}
          editData={editData}
          isView={state?.isView}
          getTableData={getTableData}
        />
      ) : undefined}

        {isAddUser === true ? (
            <AdduserModal
              visible={true}
              onCancel={onCancel}
              onSave={(value) => {
                console.log(value);
              }}
              editData={editData}
              isView={state?.isView}
              getTableData={getTableData}
            />
          ) : undefined
        }
    </>
  );
}

export default Users;
