import { SaveOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  theme,
  Upload,
  message,
  Image,
} from 'antd';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  openNotificationWithIcon,
  URL_WITH_VERSION,
} from 'shared';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Option } = Select;

function NewsSectionForm({ visible, onCancel, editMode, editData }) {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [allImages, setAllImages] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [PlanOptions, setPlanOptions] = useState([
    { value: 0, label: 'Pending' },
    { value: 1, label: 'Approved' },
  ]);

  const { token } = theme.useToken();

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  useEffect(() => {
    if (editData) {
      setFormData(editData);
      if (editData.image_urls && editData.image_urls.length > 0) {
        setImageUrl(editData.image_urls[1].url);
        setAllImages(editData.image_urls)
      }
    } else {
      setImageUrl('');
      setFileList([]);
    }
  }, [editData]);

  const [form] = Form.useForm();

  const handleSave = async (values) => {

    let _method = 'post';
    let url = `${URL_WITH_VERSION}accounts/news-letter-saving`;

    if(values.id){
      _method = 'put'
      url = `${URL_WITH_VERSION}accounts/update-news-letter-image-details/${values.id}`;
    }

    if(allImages && allImages.length === 0){
      message.error('Please upload at least one image');
      return;
    }

    form
      .validateFields()
      .then((values) => {
        if (editData?.id) values['id'] = editData?.id;

        values['image_urls'] = allImages.image_urls || allImages;

        fetch(url, {
          method: _method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === true) {
              openNotificationWithIcon('success', 'Details saved successfully');
              window.getNewsTableData()
              onCancel();
            } else {
              openNotificationWithIcon('info', data.msg);
            }
          })
          .catch((error) => {
            openNotificationWithIcon('error', 'Please fill all mandatory fields');
          });
      })
      .catch((errorInfo) => {
        openNotificationWithIcon('error', 'Please fill all mandatory fields');
      });
  };

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
    }
  }, [formData, form]);

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      setImageUrl(URL.createObjectURL(info.file.originFileObj));
      setFileList([info.file]);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleImageUpload = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('image_urls', file);

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}accounts/uploading-image-newsletter`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data.image_urls) {
        onSuccess(null, file);
        message.success(`${file.name} file uploaded successfully`);
        setAllImages(data.image_urls);
        setImageUrl(data.image_urls[0].url);
        form.setFieldsValue({ image_urls: data.image_urls[0].url });
      } else {
        onError();
        message.error(`${file.name} file upload failed.`);
      }
    } catch (error) {
      onError();
      message.error(`${file.name} file upload failed.`);
    }
  };

  const handleSubmit = (values) => {
    handleSave(values);
  };

  const handleQuillChange = (value) => {
    form.setFieldsValue({ details: value });
  };

  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>News Section</div>}
      open={visible}
      onCancel={onCancel}
      width="60%"
      footer={false}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        style={formStyle}
        {...formItemLayout}
      >
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: 'Please input your Title!' }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Publish By"
              name="created_by"
              rules={[
                { required: true, message: 'Please input your Created By!' },
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          {editData ? <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="ID"
              name="id"
              rules={[
                { required: true, message: 'Please input the plan status!' },
              ]}
            >
              <Input disabled style={{color: 'black'}} />
            </Form.Item>
          </Col> : ''}
        {/* </Row>
        <Row gutter={24}> */}
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Plan Status"
              name="status"
              rules={[
                { required: true, message: 'Please input the plan status!' },
              ]}
            >
              <Select value={editData?.status} options={PlanOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col xs={48} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="News Body"
              name="details"
              rules={[
                { required: true, message: 'Please input your new body!' },
              ]}
            >
              <ReactQuill
                theme="snow"
                onChange={handleQuillChange}
                style={{ height: '200px', marginBottom: '40px' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={48}>
          <Col xs={48} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="News Image"
              name="image_urls"
              style={{ display: 'flex', columnGap: '16px' }}
              // rules={[{ required: true, message: 'Please upload an image!' }]}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '16px',
                }}
              >
                {imageUrl && (
                  <Image
                    style={{ marginRight: '10px' }}
                    width={100}
                    src={imageUrl}
                  />
                )}
              </div>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={handleImageUpload}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

NewsSectionForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
};

export default NewsSectionForm;
