// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

import DataTable from 'react-data-table-component';

// Data
import { useEffect, useState } from 'react';
import { getAPICall } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import axios from 'axios';
import { RoomTwoTone } from '@mui/icons-material';
import { Button, Icon, Input, SvgIcon } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MDInputRoot from 'components/MDInput/MDInputRoot';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import AddCompanyForm from 'layouts/addCompany';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from 'shared';
import { Badge, Tooltip } from '@material-ui/core';
import { message } from 'antd';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import SubscribeOceannMail from './subscribeMail';
import Swal from 'sweetalert2';

// eslint-disable-next-line react/prop-types
const ButtonTagS = ({ row }) => {
  const [userLoadin, setUserLoading] = useState(false);

  // eslint-disable-next-line react/prop-types
  if (row.is_vm_db_created === false) {
    return (
      <Button
        onClick={async (e) => {
          setUserLoading(true);
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');

          const raw = JSON.stringify({
            // eslint-disable-next-line react/prop-types
            id: row.id,
          });

          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
          };
          let response = await fetch(
            `${URL_WITH_VERSION}accounts/create-vm-db`,
            requestOptions,
          );
          let data = await response.json();

          await Swal.fire('VM DB Script started please wait one minute...');
        }}
      >
        {userLoadin === true ? (
          <>
            <Spinner size="sm">Creating VM DB</Spinner>
            <span>Creating VM DB</span>
          </>
        ) : (
          <>Create VM DB</>
        )}
      </Button>
    );
  }
  return <Button disabled={true}> Create VM DB </Button>;
};

function CompanyList() {
  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: undefined,
    isView: false,
  });
  const [isAddCompany, setIsAddComapny] = useState(false);
  const [filtered_items, setFiltereditems] = useState({});
  const [filterText, setfilterText] = useState('');
  const [pending, setPending] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  const [ModalOpen, setModalOpen] = useState(false);
  const [ModalOpenData, setModalOpenData] = useState({});
  const [userLoading, setUserLoading] = useState({});
  const prod =
    window.location.host.includes('.com') ||
    window.location.host.includes('localhost')
      ? false
      : true;

  // const filterdata = state?.rows?.filter((item) =>
  //   JSON.stringify(item).toLocaleLowerCase().includes(filterText.toLowerCase()),
  // )||[];

  function Copy(url) {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    message.success('URL copied to clipboard');
  }

  const columns = [
    {
      name: <strong>Company Name</strong>,
      selector: (row) => row?.company_name,
      sortable: true,
      center: 'true',
      width: '150px',
    },
    {
      name: 'Company Address',
      selector: (row) => row?.company_address,
      sortable: true,
      center: 'true',
      cell: (row) => {
        if (row.company_address == null || row.company_address === undefined) {
          return <span style={{ color: 'red' }}>Not Available</span>;
        }
        return (
          <span
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {row.company_address}
          </span>
        );
      },
    },
    {
      name: 'Processing Mail Count',
      selector: (row) => row?.initial_email_count,
      sortable: true,
      center: 'true',

    },
    {
      name: 'Oceann VM URL',
      selector: (row) => row?.id,
      sortable: true,
      center: 'true',
      width: '200px',
      cell: (row) => {
        let name = row.company_name;
        if (name) {
          name = name.replace(/\s/g, '');
          if (row.VM_ACCESS) {
            return (
              <Tooltip placement="top" title="Click to Copy">
                <span
                  className="copy-url"
                  style={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    width: '200px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    color: 'navy',
                  }}
                  onClick={() =>
                    Copy(
                      `https://vm-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`,
                    )
                  }
                >
                  {`https://vm-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`}
                </span>
              </Tooltip>
            );
          } else {
            return <span style={{ color: 'red' }}>Not Subscribed</span>;
          }
        } else {
          return null;
        }
      },
    },
    {
      name: 'Admin URL',
      selector: (row) => row?.id,
      sortable: true,
      center: 'true',
      width: '200px',
      cell: (row) => {
        let name = row.company_name;
        if (name) {
          name = name.replace(/\s/g, '');
          return (
            <Tooltip placement="top" title="Click to Copy">
              <span
                className="copy-url"
                style={{
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  width: '200px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  color: 'navy',
                  fontWeight: 'bold',
                }}
                onClick={() =>
                  Copy(
                    `https://admin-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`,
                  )
                }
              >
                {`https://admin-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`}
              </span>
            </Tooltip>
          );
        } else {
          return null;
        }
      },
    },

    {
      name: 'Create VM DB',
      width: '12rem',
      center: true,
      cell: (row) => {
        // eslint-disable-next-line react/no-unknown-property
        return <ButtonTagS row={row} />;
      },
    },

    {
      name: 'OM URL',
      selector: (row) => row?.id,
      sortable: true,
      center: 'true',
      width: '200px',
      cell: (row) => {
        let name = row.company_name;
        if (name) {
          name = name.replace(/\s/g, '');
          if (row.MAIL_ACCESS) {
            return (
              <Tooltip placement="top" title="Click to Copy">
                <span
                  className="copy-url"
                  style={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    width: '200px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    color: 'navy',
                    fontWeight: 'bold',
                  }}
                  onClick={() =>
                    Copy(
                      `https://mail-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`,
                    )
                  }
                >
                  {`https://mail-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`}
                </span>
              </Tooltip>
            );
          } else {
            return <span style={{ color: 'red' }}>Not Subscribed</span>;
          }
        } else {
          return null;
        }
      },
    },
    {
      name: 'Email',
      selector: (row) => row?.email,
      sortable: true,
      center: 'true',
      width: '180px',
      cell: (row) => <span style={{ whiteSpace: 'nowrap' }}>{row?.email}</span>,
    },
    {
      name: 'Phone No.',
      selector: (row) => row?.phone_number,
      sortable: true,
      center: 'true',
    },
    {
      name: 'Status',
      selector: (row) => row?.status,
      sortable: true,
      width: '100px',
      center: 'true',
      cell: (row) => {
        if (row.status == 1 || row.status == true) {
          return (
            <Badge
              color="secondary"
              className="status-badge success"
              badgeContent="Active"
            />
          );
        }
        return (
          <Badge
            color="secondary"
            className="status-badge error"
            badgeContent="Deactivated"
          />
        );
      },
    },
    {
      name: 'View',
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <EyeOutlined onClick={() => handleView(row)} />
        </MDTypography>
      ),
      right: true,
      width: '70px',
    },
    {
      name: 'Actions',
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <EditOutlined onClick={() => handleEdit(row)} />
        </MDTypography>
      ),
      center: true,
      width: '80px',
    },
    {
      name: 'Activate OM',
      center: true,
      cell: (row) => {
        if (row.MAIL_ACCESS) {
          return (
            <>
              <Button style={{whiteSpace: 'nowrap'}} disabled={row.MAIL_ACCESS}>Already subscribed</Button>
            </>
          );
        } else {
          return (
            <Button
              style={{whiteSpace: 'nowrap'}}
              onClick={() => {
                setModalOpen(true);
                row['option'] = 'vm';
                setModalOpenData(row);
              }}
            >
              ACTIVATE
            </Button>
          );
        }
      },
    },
    {
      name: 'Activate OVM',
      center: true,
      cell: (row) => {
        if (row.VM_ACCESS) {
          return (
            <>
              <Button style={{whiteSpace: 'nowrap'}} disabled={row.VM_ACCESS}>already subscribed</Button>
            </>
          );
        } else {
          return (
            <Button
            style={{whiteSpace: 'nowrap'}}
              onClick={() => {
                setModalOpen(true);
                row['option'] = 'mail';
                setModalOpenData(row);
              }}
            >
              ACTIVATE
            </Button>
          );
        }
      },
    },
  ];

  useEffect(() => {
    getTableData();
  }, [isAddCompany]);

  const getTableData = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/company-details`,
        requestOptions,
      );
      const respdata = await resp.json();
      setTableData(respdata);
      setFilterData(respdata);
      if (respdata) {
        setState((prevState) => ({ ...prevState, rows: respdata }));
        setPending(false);
      } else {
        openNotificationWithIcon('error', 'Something went worng.');
      }
    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  };

  const handleEdit = (row) => {
    setState((prevState) => ({ ...prevState, editData: row }));
    setIsAddComapny(true);
  };

  const handleView = (row) => {
    setState((prevState) => ({ ...prevState, editData: row, isView: true }));
    setIsAddComapny(true);
  };

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      editData: undefined,
      isView: false,
    }));
    setIsAddComapny(false);
  };

  const { editData } = state;

  return (
    <>
      {ModalOpen === true ? (
        <>
          <SubscribeOceannMail row={ModalOpenData} modalState={setModalOpen} />
        </>
      ) : (
        <></>
      )}
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    minWidth: '200px',
                    maxWidth: '600px',
                  }}
                  onChange={(e) => {
                    setfilterText(e.target.value);
                    setFilterData(
                      tableData.filter((item) =>
                        JSON.stringify(item)
                          .toLocaleLowerCase()
                          .includes(e.target.value.toLocaleLowerCase()),
                      ),
                    );
                  }}
                />
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddComapny(true)}
                >
                  Add Company
                </MDButton>
              </div>
              <br />
              <br />
              <Card>
                <MDBox>
                  <DataTable
                    columns={columns}
                    data={filterdata}
                    pagination
                    progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      {isAddCompany === true ? (
        <AddCompanyForm
          visible={true}
          onCancel={onCancel}
          editData={editData}
          isView={state?.isView}
        />
      ) : undefined}
    </>
  );
}

export default CompanyList;
